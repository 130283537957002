import React from 'react'
import './App.css'
import './model/init'

import Page from './component/Page'
import { Provider } from 'react-redux'
import Store from './store'

const App = () => {
  return (
    <Provider store={Store}>
      <Page />
    </Provider>
  )
}

export default App