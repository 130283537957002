import store from '../../store'
import glovebox from '@gloveboxhq/glovebox-sdk-js'
import model from '../index'

const add = async (rec) => {
	const token = await model.action('app.getUserToken')

	const add = {
		data: {
			...rec,
		}
	}

	const res = await glovebox.req({
		endpoint: 'carriers',
		method: 'post',
		data: add,
		token
	})
	return res.data
}

const getById = async ({ carrierId }) => {
	if (!carrierId) return;
	
	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		endpoint: `carriers/${carrierId}`,
		method: 'get',
		token
	})
	store.dispatch({
		type: 'CARRIER_REC_SET',
		payload: { rec: res.data },
	})
}

const getList = async () => {
	const res = await glovebox.req({
		endpoint: `carriers`,
		method: 'get',
	})

	const listActive = res.data.filter(rec => {
		return true // rec.enabled
	})

	store.dispatch({
		type: 'CARRIER_LIST_ACTIVE_SET',
		payload: { listActive },
	})
}

const save = async ({ rec, commercial }) => {
	const token = await model.action('app.getUserToken')

	if (commercial) {
		let save = {
			data: { ...commercial }
		}

		await glovebox.req({
			endpoint: `carriers/${commercial.id}`,
			method: 'put',
			data: save,
			token
		})

		store.dispatch({
			type: 'CARRIER_REC_SET',
			payload: { rec: commercial },
		})

		return commercial
	} else {
		let save = {
			data: { ...rec }
		}

		await glovebox.req({
			endpoint: `carriers/${rec.id}`,
			method: 'put',
			data: save,
			token
		})

		store.dispatch({
			type: 'CARRIER_REC_SET',
			payload: { rec },
		})

		return rec
	}
}

const uploadImage = async ({ carrierId, image }) => {
	const token = await model.action('app.getUserToken')

	const data = new FormData();
	data.append('file', image)
	const headers = {
		'content-type': 'multipart/form-data'
	}

	await glovebox.req({
		endpoint: `carriers/${carrierId}/logo`,
		headers,
		method: 'put',
		data,
		token
	})

	await getById({ carrierId })
}

model.registerActions('carrier', {
	add,
	getById,
	getList,
	save,
	uploadImage
})