
const clear = (key)=>{
	delete localStorage[key]
}
const get = (key)=>{
	if (localStorage[key] === undefined) return undefined
	return JSON.parse(localStorage[key])
}
const set = (key, val)=>{
	if (val === undefined){
		delete localStorage[key]
		return undefined
	}
	localStorage[key] = JSON.stringify(val)
	return localStorage[key]
}

const localStore = {
	clear,
	get,
	set,
}

export default localStore