import React, { useState } from 'react'

import model from '../../model'

import { onFieldChange, onFormSubmit } from '../../utility/form'

const AgencyCardEdit = ({ handleClose, rec, handleComplete }) => {
    const [form, setForm] = useState({
        image: '',
        theme: rec.theme || 'light'
    })

    const handleChange = (name, value) => {
        const formData = {
            ...form,
            [name]: value,
        }

        setForm(formData);
    }

    const handleSubmit = async () => {
        await model.action('app.setError', null)
        try {
            await model.action('app.setLoading', true)
            if (form.image) {
                await model.action('agency.uploadImage', { agencyId: rec.id, image: form.image })
            }

            await model.action('app.setLoading', false)
            await handleComplete()
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    return (
        <form className="row justify-content-center mb-3" onSubmit={e => onFormSubmit(e, handleSubmit)}>
            <div className="col-md-8">
                <div className="section-title">
                    Agency Card
                    <span className="section-close" onClick={handleClose}><i className="far fa-times"></i></span>
                </div>
                <div className="form-row">
                    <label>Image</label>
                    <input
                        className="form-control"
                        name="image"
                        accept=".jpg,.png,.gif,image/jpeg,image/gif,image/png"
                        onChange={e => onFieldChange(e, handleChange)}
                        placeholder="Image file"
                        type="file" />
                    <div className="form-hint">
                        Image should be 450x450 and in png format
                    </div>
                </div>
                <div className="form-actions">
                    <button className="btn btn-primary btn-block">Save Card</button>
                </div>
            </div>
        </form>
    )
}


export default AgencyCardEdit;

