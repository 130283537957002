import constants from './constants'
import model from '../index'

	const list = state => {
		let recs = []
		if (!state.user.list) return null
		state.user.list.forEach(id => {
			recs.push(state.user.recs[id])
		})
		return recs
	}

	const rec = state => state.user.rec

	const recById =  (state, id) =>{
		return state.user.recs[id] // .find(r => r.id === id)
	}

	const policyHolder = state => {
		if (!state.user.policyHolder) return null

		return state.user.policyHolder;
	}

	const getSearchQuery = state => {
		if(!state.user.searchQuery) return null

		return state.user.searchQuery
	}

	model.registerSelectors(constants.model, {
		list,
		rec,
		recById,
		policyHolder,
		getSearchQuery
	})