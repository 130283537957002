import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const AgenciesList = ({ list }) => {
  const [showCopied, setShowCopied] = useState(false);
  const [copyIndex, setCopyIndex ] = useState(false);

  const handleCopyClick = (id, i) => {

    navigator.clipboard.writeText(id);

    setCopyIndex(i)
    setShowCopied(true);

    setTimeout(() => {
      setCopyIndex(false)
      setShowCopied(false)
    }, 2000)
  }

  return (
    <div>
      {list.length > 0 ? list.map((rec, i) => {
        const imageUrl = rec.logo
        const className = `agency-card agency-card-sm ${rec.theme === 'dark' ? 'dark' : ''}`
        const style = { backgroundImage: `url(${imageUrl})` || '', margin: '0.5rem' }
        const rowLink = `/agency/${rec.id}`

        return (
          <React.Fragment key={rec.id}>
            <div className="agency-row row">
              <div className="col-sm-4">
                <div className={className}>
                  <Link to={rowLink} className="agency-card-holder">
                    <div className="agency-card-icon-holder" style={style}>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6">
                <div>
                  <Link className="agency-row-name" to={rowLink}>{rec.name}</Link>
                  <div className="mt-2">
                  <button className="btn btn-outline-light" onClick={() => handleCopyClick(rec.id, i)}>
                    {showCopied && copyIndex === i ?
                      <React.Fragment>
                        Copied Agency ID
                      </React.Fragment>
                      :
                      <React.Fragment>
                        Agency ID - {rec.id}
                      </React.Fragment>
                    }
                  </button>
                  </div>
                </div>
                <div className="agency-row-agents">
                  {rec.agents.length > 0 ?
                    <div className="text-light">{rec.agents.length} Agents</div>
                    :
                    <div />
                  }
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }) : <div className="text-center pt-5">No Agencies Found</div>}
    </div>
  )
}

export default AgenciesList