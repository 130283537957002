
let config = {
	userCookieName: 'token',
	userRecName: 'glovebox-user-rec',
	userTokenName: 'glovebox-user',
	version: 'v1',
	xappCookieName: 'glovebox-xapp-token',
}

if (process.env.REACT_APP_ENV === 'prod'){
	config = {
		...config,
		agencyConnectUrl: 'https://connect.gloveboxapp.com/agency?id=',
		apiHost: 'api.gloveboxapp.com',
		appID: 'AwUADgAACQAFAwMLBAUHBQ', // using iOS hash for now
		appSecret: '0OTw4EidatFtHp8JOsXb/wBz/NQdOsgj0wmrOCW4Gbk=',
		domain: 'https://api.gloveboxapp.com',
		webApp: 'https://admin.gloveboxapp.com',
		clientWebApp: 'https://my.gloveboxapp.com',
		xappCookieDomain: '.gloveboxapp.com',
		hqApp: 'https://hq.gloveboxapp.com/',
	}
} else {
	config = {
		...config,
		agencyConnectUrl: 'https://connect-dev.gloveboxapp.com/agency?id=',
		apiHost: 'api-dev.gloveboxapp.com',
		appID: 'AwUADgAACQAFAwMLBAUHBQ', // using iOS hash for now
		appSecret: '0OTw4EidatFtHp8JOsXb/wBz/NQdOsgj0wmrOCW4Gbk=',
		domain: 'https://api-dev.gloveboxapp.com',
		webApp: 'https://admin-dev.gloveboxapp.com',
		clientWebApp: 'https://my-dev.gloveboxapp.com',
		xappCookieDomain: '.gloveboxapp.com',
		hqApp: 'https://hq-dev.gloveboxapp.com/',
	}
}

export default config

/*

HTML5 Web App:
		App ID:	GMFbRITqKEsPVNWCRArZKL
		App Shared Secret (HMAC SHA-256) [Hex]:
		140d74e329292000273a82d743621e2e43e1482a8009b41998a2477044c27006
		Shared Secret (HMAC SHA-256) [Base64]:
		FA104ykpIAAnOoLXQ2IeLkPhSCqACbQZmKJHcETCcAY=


	iOS App:
		App ID: AwUADgAACQAFAwMLBAUHBQ
		App Shared Secret (HMAC SHA-256) [Hex]:
		d0e4f0e0489d6ad16d1e9f093ac5dbff0073fcd41d3ac823d309ab3825b819b9
		Shared Secret (HMAC SHA-256) [Base64]:
		0OTw4EidatFtHp8JOsXb/wBz/NQdOsgj0wmrOCW4Gbk=

*/