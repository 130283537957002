import React, { useState } from 'react'
import AgencyCardEdit from './AgencyCardEdit'


const AgencyCard = ({ rec }) => {
  const [editForm, setEditForm] = useState(false);
  const [showEditOnce, setShowEditOnce] = useState(false);
  const imageUrl = rec.logo

  const handleLoadError = () => {
    if (!showEditOnce) {
      setShowEditOnce(true);
      setEditForm(true);
    }
  }

  return (
    <>
      {editForm ? <AgencyCardEdit handleClose={() => setEditForm(false)} handleComplete={() => setEditForm(false)} rec={rec} />
        :
        <div className="row justify-content-center mb-3">
          <div className="col-md-8">
            <div className={`agency-card d-block ${rec.theme}`} onClick={() => setEditForm(true)}>
              <div className="agency-card-holder d-flex align-items-center">
                <div className="card-close">
                  <i className="far fa-pen" aria-hidden="true"></i>
                </div>
                <div className="text-center w-100">
                  <div className="agency-card-icon-holder" style={{ backgroundImage: `url(${imageUrl})` || '', margin: '3rem' }} />
                  <img className="agency-card-icon d-none" onError={handleLoadError} src={imageUrl} alt="agency icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AgencyCard