import React from 'react'

import { ReactComponent as PolicyTypeArticles } from '../../icon/policy-type-articles.svg'
import { ReactComponent as PolicyTypeAuto } from '../../icon/policy-type-auto.svg'
import { ReactComponent as PolicyTypeBoat } from '../../icon/policy-type-boat.svg'
import { ReactComponent as PolicyTypeDental } from '../../icon/policy-type-dental.svg'
import { ReactComponent as PolicyTypeDisability } from '../../icon/policy-type-disability.svg'
import { ReactComponent as PolicyTypeEarthquake } from '../../icon/policy-type-earthquake.svg'
import { ReactComponent as PolicyTypeFlood } from '../../icon/policy-type-flood.svg'
import { ReactComponent as PolicyTypeHealth } from '../../icon/policy-type-health.svg'
import { ReactComponent as PolicyTypeHome } from '../../icon/policy-type-home.svg'
import { ReactComponent as PolicyTypeLife } from '../../icon/policy-type-life.svg'
import { ReactComponent as PolicyTypeMotorcycle } from '../../icon/policy-type-motorcycle.svg'
import { ReactComponent as PolicyTypeMotorhome } from '../../icon/policy-type-motorhome.svg'
import { ReactComponent as PolicyTypeOther } from '../../icon/policy-type-other.svg'
import { ReactComponent as PolicyTypePackage } from '../../icon/policy-type-package.svg'
import { ReactComponent as PolicyTypePet } from '../../icon/policy-type-pet.svg'
import { ReactComponent as PolicyTypeRental } from '../../icon/policy-type-rental.svg'
import { ReactComponent as PolicyTypeRV } from '../../icon/policy-type-rv.svg'
import { ReactComponent as PolicyTypeToy } from '../../icon/policy-type-toy.svg'
import { ReactComponent as PolicyTypeTravel } from '../../icon/policy-type-travel.svg'
import { ReactComponent as PolicyTypeUmbrella } from '../../icon/policy-type-umbrella.svg'
import { ReactComponent as PolicyTypeVision } from '../../icon/policy-type-vision.svg'
import { ReactComponent as PolicyTypeWindhail } from '../../icon/policy-type-windhail.svg'

const policyTypeToIcon = {
	articles: PolicyTypeArticles,
	auto: PolicyTypeAuto,
	boat: PolicyTypeBoat,
	dental: PolicyTypeDental,
	disability: PolicyTypeDisability,
	earthquake: PolicyTypeEarthquake,
	flood: PolicyTypeFlood,
	health: PolicyTypeHealth,
	home: PolicyTypeHome,
	life: PolicyTypeLife,
	motorcycle: PolicyTypeMotorcycle,
	motorhome: PolicyTypeMotorhome,
	other: PolicyTypeOther,
	package: PolicyTypePackage,
	pet: PolicyTypePet,
	rentalproperty: PolicyTypeRental,
	rv: PolicyTypeRV,
	toy: PolicyTypeToy,
	travel: PolicyTypeTravel,
	umbrella: PolicyTypeUmbrella,
	vision: PolicyTypeVision,
	windhail: PolicyTypeWindhail,
}

const PolicyTypeIcon = ({ className, policyType }) => {
	const Icon = policyTypeToIcon[policyType]
	if (!Icon) return <div />
	return <Icon className={className} />
}

export default PolicyTypeIcon