import model from '../index'
import constants from './constants'

const areObjectsEqual = ({commercial, rec}) => {
    // if either object comes in as empty object, return false
    if (Object.keys(commercial).length === 0 || Object.keys(rec).length === 0) {
        return true;
    }
    const obj1Keys = Object.keys(commercial);
    const obj2Keys = Object.keys(rec);

    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }

    for (let i = 0; i < obj1Keys.length; i++) {
        const key = obj1Keys[i];
        const val1 = commercial[key];
        const val2 = rec[key];

        if (val1 !== val2) {
            return false;
        }
    }

    return true;
};

model.registerHelpers(constants.model, {
    areObjectsEqual
})