import constants from './constants'
import model from '../index'

	const agentById = ({ state, id }) =>{
		return state.agency.agentRecs[id] // .find(r => r.id === id)
	}

	const agents = ({ state, agencyId }) => {
		let recs = []
		if (!state.agency.agentLists || !state.agency.agentLists[agencyId]) return null
		state.agency.agentLists[agencyId].forEach(id => {
			recs.push(state.agency.agentRecs[id])
		})
		return recs
	}

	const all = state => {
		let recs = []
		if (!state.agency.all) return null
		state.agency.all.forEach(id => {
			recs.push(state.agency.recs[id])
		})
		return recs
	}

	const list = state => {
		let recs = []
		if (!state.agency.list) return null
		state.agency.list.forEach(id => {
			recs.push(state.agency.recs[id])
		})
		return recs
	}

	const recById = ({ state, id }) =>{
		return state.agency.recs[id] // .find(r => r.id === id)
	}

	const filterEnabled = state => {
		return state.agency
	}

	const prevAgencySearch = state => {
		if (!state.agency.agencySearchResults) return null

		return state.agency.agencySearchResults;
	}

	const prevQuerySearch = state => {
		if (!state.agency.searchQuery) return null

		return state.agency.searchQuery;
	}

	const featureFlagsGet = ({state, agencyId}) => {
		if (!state.agency.featureFlags) return null
		return state.agency.featureFlags[agencyId]
	}

	model.registerSelectors(constants.model, {
		agentById,
		agents,
		all,
		featureFlagsGet,
		list,
		recById,
		filterEnabled,
		prevAgencySearch,
		prevQuerySearch
	})
