import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { onFieldChange, onFormSubmit } from '../../utility/form'
import model from '../../model'
import PolicyHolderList from './PolicyHolderList'

const PolicyHolders = () => {
  const policyHolder = useSelector(state => model.selector('user.policyHolder', state))
  const prevQuery = useSelector(state => model.selector('user.getSearchQuery', state))
  const [hits, setHits] = useState(0)
  const [policyInput, setPolicyInput] = useState('');
  const [results, setResults] = useState([]);
  const [hidden, setHidden] = useState(true);
  let searchTimeout = null;

  useEffect(() => {
    (async () => {
      if (policyHolder) {
        setHidden(false)
        setHits(policyHolder.length)
        setResults(policyHolder)

        if (prevQuery) {
          setPolicyInput(prevQuery)
        }
      }
    })()
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
    }
  }, [policyHolder, prevQuery, searchTimeout])

  const search = async () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(async () => {
      searchTimeout = null
      try {
        const getPolicy = await model.action('user.getPolicyHolder', policyInput);
        const results = getPolicy.results;
  
        setHidden(false)
        setHits(results.length)
        setResults(results);
      } catch (e) {
        await model.action('app.setError', e)
      }
    }, 200)
  }

  const newHandleChange = (name, value) => {
    setPolicyInput(value)
  }

  const handleSubmit = () => {
    search();
  }

  return (
    <div className="my-3">
      <div className="container section-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h3 className="app-title-bar">
              Policy Holders
            </h3>
            <div className="section-title">
              Search for a user by name, phone, email or policy #
            </div>
            <form className="mb-5" onSubmit={e => onFormSubmit(e, handleSubmit)}>
              <div className="form-row">
                <label>Policy Info</label>
                <input
                  className="form-control form-control-lg"
                  name="policyInput"
                  onChange={e => onFieldChange(e, newHandleChange)}
                  placeholder="Policy Holder Info"
                  type="text"
                  value={policyInput} />
              </div>
              <div className="form-row-actions pt-2 clearfix">
                <button className="btn btn-primary float-right" type="submit">SEARCH</button>
              </div>
            </form>
            <PolicyHolderList list={results} policyInput={policyInput} hits={hits} hidden={hidden} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PolicyHolders

