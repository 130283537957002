import constants from './constants'
import model from '../index'

const idToNameMap = (state) => {
	let map = {}
	if (!state.carrier.listActive) return null
	state.carrier.listActive.forEach(r => {
		map[r.id] = r.name
	})
	return map
}

const listActive = state => state.carrier.listActive

const recById = ({ state, id }) => {
	if (!state.carrier.recs) return null
	return state.carrier.recs[id]
}

const recByGroupId = ({ state, groupId }) => {
	if (!state.carrier.listActive) return null
	return state.carrier.listActive.filter(r => r.groupID === groupId)
}

model.registerSelectors(constants.model, {
	idToNameMap,
	listActive,
	recByGroupId,
	recById,
})
