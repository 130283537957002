import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import model from '../../model'
import AgenciesList from './AgenciesList'
import Pagination from '../Pagination'
import Tooltip from '../ToolTip'
import { onFieldChange } from '../../utility/form'

const Agencies = () => {
  const all = useSelector(state => model.selector('agency.all', state))
  const filterState = useSelector(state => model.selector('agency.filterEnabled', state))
  const prevSearchResult = useSelector(state => model.selector('agency.prevAgencySearch', state))
  const prevQuery = useSelector(state => model.selector('agency.prevQuerySearch', state))
  const [search, setSearch] = useState('');
  const [agencies, setAgencies] = useState([]);
  const [allSearch, setAllSearch] = useState([]);
  const [enableFilter, setEnableFilter] = useState(filterState?.filterEnabled);
  const [agenciesPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [toolTip, setToolTip] = useState(null);
  const link = '/agencies/add'

  const clearSearch = (list) => {
    if (!list) return [];

    setSearch('');
    setAllSearch([])
    if (enableFilter) {
      setAgencies(list.filter((item) => item.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1));
    } else {
      setAgencies(list.filter((item) => !item.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1))
    }
  }

  const handleToolTipToggle = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (toolTip) {
      setToolTip(false);
    } else {
      setToolTip(true);
    }
  }

  const handleChange = (name, value) => {
    sessionStorage.setItem('searchAgencies', value);
    setSearch(value);
  }

  const enabledDisabled = async (list) => {
    if (!list) return [];

    await model.action('agency.toggleAgentFilter')
    filterList(list)
    setEnableFilter(!enableFilter);
  }

  const filterList = useCallback((list) => {
    if (!list) return [];

    if (enableFilter) {
      setAgencies(list.filter((item) => !item.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1))
    } else {
      setAgencies(list.filter((item) => item.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1));
    }
  }, [enableFilter])

  const submitForm = async (e) => {
    e.preventDefault()
    if (!search) {
      setAgencies(all)
      clearSearch(all)
      return;
    }

    const searchAgency = await model.action('agency.searchAgency', search);
    setAllSearch(searchAgency);

    if (enableFilter) {
      setAgencies(searchAgency.filter((item) => item.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1));
    } else {
      setAgencies(searchAgency.filter((item) => !item.enabled).sort((a, b) => (a.name > b.name) ? 1 : -1))
    }
  }

  useEffect(() => {
    (async () => {
      try {
        if (!prevSearchResult) {
          await model.action('app.setLoading', true)
          const rec = await model.action('agency.getAll')
          const tmpArr = Object.values(rec);
          setEnableFilter(!enableFilter);
          setAgencies(tmpArr);
          filterList(tmpArr)
          await model.action('app.setLoading', false)
        } else {
          await model.action('app.setLoading', true)
          setSearch(prevQuery)
          setAllSearch(prevSearchResult);
          setEnableFilter(!enableFilter);
          filterList(prevSearchResult)
          await model.action('app.setLoading', false)
        }
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const indexOfLastAgency = currentPage * agenciesPerPage;
  const indexOfFirstAgency = indexOfLastAgency - agenciesPerPage;
  const currentAgencies = agencies.slice(indexOfFirstAgency, indexOfLastAgency);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const arrayToFilter = search ? allSearch : all

  return (
    <div className="my-3">
      <div className="container section-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h3 className="app-headline-bar">Agencies</h3>
            <form onSubmit={submitForm}>
              <h3 className="app-headline-bar d-flex align-items-center">
                <i className="far fa-search"></i>
                <input
                  autoFocus
                  className="flex-grow-1 form-control mx-3"
                  onChange={e => onFieldChange(e, handleChange)}
                  name="search"
                  placeholder="search for an agency"
                  type="text"
                  value={search}
                />
                <div className="text-light float-right cursor-pointer mr-3" onMouseEnter={(e) => handleToolTipToggle(e)} onMouseLeave={(e) => handleToolTipToggle(e)}>
                  <i className="far fa-minus-square" onClick={() => clearSearch(all)}></i>
                  {toolTip && <Tooltip tip="Clear Search" className="tooltip-clear" secondaryClass="tooltip-pos" />}
                </div>
                <div className={`input-toggle float-right mr-3 ${!enableFilter ? 'input-toggle-off' : ''}`} onClick={(e) => enabledDisabled(arrayToFilter)}>
                  <div className="input-toggle-knob" />
                </div>
                <Link to={link} className="text-light float-right cursor-pointer">
                  <i className="far fa-plus-square"></i>
                </Link>
              </h3>
            </form>
            <AgenciesList list={currentAgencies} />
            <div className="d-flex justify-content-center align-items-center">
              <Pagination perPage={agenciesPerPage} total={agencies.length} paginate={paginate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Agencies;