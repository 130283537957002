import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onFieldChange, onFormSubmit } from '../../utility/form'
import model from '../../model'

const CarrierDetails = ({ rec, isCommercial, setIsCommercial, recCommercial }) => {
  const navigate = useNavigate();
  const user = useSelector(state => model.selector('user.rec', state));
  const [createPersonal, setCreatePersonal] = useState(!rec && recCommercial ? true : false);
  const [createCommercial, setCreateCommercial] = useState(!recCommercial && rec ? true : false);

  const [recState, setRecState] = useState({
    name: recCommercial?.name || '',
    id: '',
    theme: recCommercial?.theme || 'light',
    enabled: '',
    division: 'personal',
    groupID: recCommercial?.groupID,
    registerEntryURL: '',
    registerExitURL: '',
    forgotPasswordURL: '',
    loginURL: '',
    payBillURL: '',
    claimsURL: '',
    telematicsURL: '',
    roadsideServiceURL: '',
    roadsideServicePhone: '',
  });
  const [commercialState, setCommercialState] = useState({
    name: rec?.name || '',
    id: '',
    theme: rec?.theme || 'light',
    enabled: '',
    division: 'commercial',
    groupID: rec?.groupID,
    registerEntryURL: '',
    registerExitURL: '',
    forgotPasswordURL: '',
    loginURL: '',
    payBillURL: '',
    claimsURL: '',
    telematicsURL: '',
    roadsideServiceURL: '',
    roadsideServicePhone: '',
  });

  useEffect(() => {
    setRecState(rec ? rec : {
      name: recCommercial?.name || '',
      id: '',
      theme: recCommercial?.theme || 'light',
      enabled: '',
      division: 'personal',
      groupID: recCommercial?.groupID,
      registerEntryURL: '',
      registerExitURL: '',
      forgotPasswordURL: '',
      loginURL: '',
      payBillURL: '',
      claimsURL: '',
      telematicsURL: '',
      roadsideServiceURL: '',
      roadsideServicePhone: '',
    });
    setCommercialState(recCommercial ? recCommercial : {
      name: rec?.name || '',
      id: '',
      theme: rec?.theme || 'light',
      enabled: '',
      division: 'commercial',
      groupID: rec?.groupID,
      registerEntryURL: '',
      registerExitURL: '',
      forgotPasswordURL: '',
      loginURL: '',
      payBillURL: '',
      claimsURL: '',
      telematicsURL: '',
      roadsideServiceURL: '',
      roadsideServicePhone: '',
    });
    setCreatePersonal(!rec && recCommercial ? true : false);
    setCreateCommercial(!recCommercial && rec ? true : false);
  }, [rec, recCommercial]);

  const handleChange = (name, value) => {
    if (name.indexOf('.') !== -1) {
      const parts = name.split('.')
      let set = JSON.parse(JSON.stringify(recState)), piece
      parts.forEach((p, i) => {
        if (i === parts.length - 1) {
          piece[p] = value
        } else {
          piece = set[p]
        }
      })
      setRecState(set)
      return
    }

    setRecState(currentState => ({ ...currentState, [name]: value }));
  }

  const handleCommercialChange = (name, value) => {
    if (!commercialState) {
      setCommercialState({})
    }
    if (name.indexOf('.') !== -1) {
      const parts = name.split('.')
      let set = JSON.parse(JSON.stringify(commercialState)), piece
      parts.forEach((p, i) => {
        if (i === parts.length - 1) {
          piece[p] = value
        } else {
          piece = set[p]
        }
      })
      setCommercialState(set)
      return
    }

    setCommercialState(currentState => ({ ...currentState, [name]: value }));
  }

  const handleSubmit = async () => {
    let saveRec = { ...recState }
    Object.keys(saveRec).forEach((i) => {
      if (saveRec[i] === '') saveRec[i] = null
    })

    let saveCommercial = commercialState ? { ...commercialState } : null
    if (saveCommercial) {
      Object.keys(saveCommercial).forEach((i) => {
        if (saveCommercial[i] === '') saveCommercial[i] = null
      })
    }

    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      if (createPersonal && !isCommercialChanged) {
        await model.action('carrier.add', saveRec)
        setCreatePersonal(false)
        navigate(`/personal/${saveRec.id}/commercial/${recCommercial.id}`)
      }

      if (createCommercial && !isRecChanged) {
        await model.action('carrier.add', saveCommercial)
        setCreateCommercial(false)
        navigate(`/personal/${rec.id}/commercial/${saveCommercial.id}`)
      }

      if (isCommercialChanged) {
        await model.action('carrier.save', { commercial: saveCommercial })
      }

      if (isRecChanged) {
        await model.action('carrier.save', { rec: saveRec })
      }
      await model.action('app.setLoading', false)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  const isRecChanged = rec && Object.keys(recState).find(key => {
    if (key === 'updated' || key === 'image' || key === 'logo') return false

    if (rec && rec[key] !== recState[key]) {
      return true
    }
    return false
  }) !== undefined

  const isCommercialChanged = recCommercial && Object.keys(commercialState).find(key => {
    if (key === 'updated' || key === 'image' || key === 'logo') return false

    if (recCommercial && recCommercial[key] !== commercialState[key]) {
      return true
    }
    return false
  }) !== undefined;

  return (
    <React.Fragment>
      <form className="row justify-content-center mb-5 pb-5" onSubmit={e => onFormSubmit(e, handleSubmit)}>
        <div className="col-md-8">
          <div className="section-title mt-3">
            Carrier Status
          </div>
          <div className="form-row">
            <label>Division</label>
            <div className="form-control">
              <div className="btn-group">
                <div className={`btn ${!isCommercial ? 'btn-primary' : 'btn-dark'}`} onClick={() => setIsCommercial(false)}>
                  Personal
                </div>
                <div className={`btn ${isCommercial ? 'btn-primary' : 'btn-dark'}`} onClick={() => setIsCommercial(true)}>
                  Commercial
                </div>
              </div>
            </div>
          </div>
          {user && user.role && user.role.id === "admin" ?
            <>
              {!isCommercial ?
                <div className="form-row">
                  <label>Active</label>
                  <div className="form-control">
                    <div className="btn-group">
                      <div className={`btn ${rec.enabled === true ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('enabled', true)}>
                        ENABLED
                      </div>
                      <div className={`btn ${rec.enabled === false ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('enabled', false)}>
                        DISABLED
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="form-row">
                  <label>Active</label>
                  <div className="form-control">
                    <div className="btn-group">
                      <div className={`btn ${commercialState.enabled === true ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('enabled', true)}>
                        ENABLED
                      </div>
                      <div className={`btn ${commercialState.enabled === false ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('enabled', false)}>
                        DISABLED
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
            :
            <>
              {!isCommercial ?
                <div className="form-row">
                  <label>Active</label>
                  <div className="form-control">
                    {recState.enabled ? 'ENABLED' : 'DISABLED'}
                  </div>
                </div>
                :
                <div className="form-row">
                  <label>Active</label>
                  <div className="form-control">
                    {commercialState.enabled ? 'ENABLED' : 'DISABLED'}
                  </div>
                </div>
              }
            </>}
          <div className="section-title mt-3">
            Carrier Details
          </div>
          {isCommercial ? (
            <React.Fragment>
              {createCommercial ? (
                <div className="form-row">
                  <label>ID</label>
                  <input
                    className="form-control"
                    name="id"
                    onChange={e => onFieldChange(e, handleCommercialChange)}
                    placeholder="Enter unique carrier ID"
                    type="text"
                    value={commercialState.id}
                  />
                </div>
              ) : <div />}
              <div className="form-row">
                <label>Name</label>
                <input
                  className="form-control"
                  name="name"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="Name"
                  type="text"
                  value={commercialState.name || ''}
                />
              </div>
              <div className="form-row">
                <label>Theme</label>
                <div className="form-control">
                  <div className="btn-group">
                    <div
                      className={`btn ${commercialState.theme === 'light' ? 'btn-primary' : 'btn-dark'}`}
                      onClick={() => handleCommercialChange('theme', 'light')}
                    >
                      Light
                    </div>
                    <div
                      className={`btn ${commercialState.theme === 'dark' ? 'btn-primary' : 'btn-dark'}`}
                      onClick={() => handleCommercialChange('theme', 'dark')}
                    >
                      Dark
                    </div>
                  </div>
                </div>
              </div>
              {commercialState.logo && (
                <div className="form-row">
                  <label>Image</label>
                  <div className="form-control">
                    <div className="btn-group">
                      <a
                        className="btn btn-outline-primary float-right width-60"
                        href={commercialState.logo + '?force=true'}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <i className="fas fa-download dl-logo" />
                      </a>
                    </div>
                  </div>
                </div>
              )
              }
              <div className="section-title mt-3">Action Links</div>
              <div className="form-row">
                <label>Register Entry URL</label>
                <input
                  className="form-control"
                  name="registerEntryURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/register"
                  type="text"
                  value={commercialState.registerEntryURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Register Exit URL</label>
                <input
                  className="form-control"
                  name="registerExitURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/register/done"
                  type="text"
                  value={commercialState.registerExitURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Forgot Password URL</label>
                <input
                  className="form-control"
                  name="forgotPasswordURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/forgot-password"
                  type="text"
                  value={commercialState.forgotPasswordURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Login URL</label>
                <input
                  className="form-control"
                  name="loginURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/login"
                  type="text"
                  value={commercialState.loginURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Pay Bill URL</label>
                <input
                  className="form-control"
                  name="payBillURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/pay-bill"
                  type="text"
                  value={commercialState.payBillURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Claims URL</label>
                <input
                  className="form-control"
                  name="claimsURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/claims"
                  type="text"
                  value={commercialState.claimsURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Telematics URL</label>
                <input
                  className="form-control"
                  name="telematicsURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/telematics"
                  type="text"
                  value={commercialState.telematicsURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Roadside Service URL</label>
                <input
                  className="form-control"
                  name="roadsideServiceURL"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="https://carrier.com/telematics"
                  type="text"
                  value={commercialState.roadsideServiceURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Roadside Service Phone</label>
                <input
                  className="form-control"
                  name="roadsideServicePhone"
                  onChange={e => onFieldChange(e, handleCommercialChange)}
                  placeholder="7201111111"
                  type="text"
                  value={commercialState.roadsideServicePhone || ''}
                />
              </div>
              {createCommercial &&
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button className="btn btn-primary">Save</button>
                </div>
              }
            </React.Fragment>
          ) : (
            <React.Fragment>
              {createPersonal ? (
                <div className="form-row">
                  <label>ID</label>
                  <input
                    className="form-control"
                    name="id"
                    onChange={e => onFieldChange(e, handleChange)}
                    placeholder="Enter unique carrier ID"
                    type="text"
                    value={recState.id}
                  />
                </div>
              ) : <div />}
              <div className="form-row">
                <label>Name</label>
                <input
                  className="form-control"
                  name="name"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="Name"
                  type="text"
                  value={recState.name}
                />
              </div>
              <div className="form-row">
                <label>Theme</label>
                <div className="form-control">
                  <div className="btn-group">
                    <div
                      className={`btn ${recState && recState.theme === 'light' ? 'btn-primary' : 'btn-dark'}`}
                      onClick={() => handleChange('theme', 'light')}
                    >
                      Light
                    </div>
                    <div
                      className={`btn ${recState && recState.theme === 'dark' ? 'btn-primary' : 'btn-dark'}`}
                      onClick={() => handleChange('theme', 'dark')}
                    >
                      Dark
                    </div>
                  </div>
                </div>
              </div>
              {recState.logo && (
                <div className="form-row">
                  <label>Image</label>
                  <div className="form-control">
                    <div className="btn-group">
                      <a
                        className="btn btn-outline-primary float-right width-60"
                        href={recState.logo + '?force=true'}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <i className="fas fa-download dl-logo" />
                      </a>
                    </div>
                  </div>
                </div>
              )
              }
              <div className="section-title mt-3">Action Links</div>
              <div className="form-row">
                <label>Register Entry URL</label>
                <input
                  className="form-control"
                  name="registerEntryURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/register"
                  type="text"
                  value={recState.registerEntryURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Register Exit URL</label>
                <input
                  className="form-control"
                  name="registerExitURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/register/done"
                  type="text"
                  value={recState.registerExitURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Forgot Password URL</label>
                <input
                  className="form-control"
                  name="forgotPasswordURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/forgot-password"
                  type="text"
                  value={recState.forgotPasswordURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Login URL</label>
                <input
                  className="form-control"
                  name="loginURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/login"
                  type="text"
                  value={recState.loginURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Pay Bill URL</label>
                <input
                  className="form-control"
                  name="payBillURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/pay-bill"
                  type="text"
                  value={recState.payBillURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Claims URL</label>
                <input
                  className="form-control"
                  name="claimsURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/claims"
                  type="text"
                  value={recState.claimsURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Telematics URL</label>
                <input
                  className="form-control"
                  name="telematicsURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/telematics"
                  type="text"
                  value={recState.telematicsURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Roadside Service URL</label>
                <input
                  className="form-control"
                  name="roadsideServiceURL"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="https://carrier.com/telematics"
                  type="text"
                  value={recState.roadsideServiceURL || ''}
                />
              </div>
              <div className="form-row">
                <label>Roadside Service Phone</label>
                <input
                  className="form-control"
                  name="roadsideServicePhone"
                  onChange={e => onFieldChange(e, handleChange)}
                  placeholder="7201111111"
                  type="text"
                  value={recState.roadsideServicePhone || ''}
                />
              </div>
              {createPersonal &&
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button className="btn btn-primary">Save</button>
                </div>
              }
            </React.Fragment>
          )}
        </div>
        {(isRecChanged || (isCommercialChanged)) && (
          <div className="save-bar">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="clearfix text-right">
                    <span className="btn btn-outline-light float-left" onClick={() => {
                      if (commercialState && isCommercialChanged) {
                        setCommercialState(recCommercial ? recCommercial : {
                          name: '',
                          id: '',
                          theme: rec.theme || 'light',
                          enabled: '',
                          division: 'commercial',
                          groupID: rec.groupID,
                          registerEntryURL: '',
                          registerExitURL: '',
                          forgotPasswordURL: '',
                          loginURL: '',
                          payBillURL: '',
                          claimsURL: '',
                          telematicsURL: '',
                          roadsideServiceURL: '',
                          roadsideServicePhone: '',
                        });
                      } else {
                        setRecState(rec ? rec : {
                          name: recCommercial.name || '',
                          id: '',
                          theme: recCommercial.theme || 'light',
                          enabled: '',
                          division: 'personal',
                          groupID: recCommercial.groupID,
                          registerEntryURL: '',
                          registerExitURL: '',
                          forgotPasswordURL: '',
                          loginURL: '',
                          payBillURL: '',
                          claimsURL: '',
                          telematicsURL: '',
                          roadsideServiceURL: '',
                          roadsideServicePhone: '',
                        });
                      }
                    }}>Revert</span>
                    Save your changes <button className="btn btn-primary ml-2">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </React.Fragment>
  )
}

export default CarrierDetails
