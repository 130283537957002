import React from 'react'

const ErrorC = ({ handleClose, message }) => {
	if (!message) return <div />

	return (
		<>
			{!message ? <div /> :
				<div className="alert-bar fixed-bottom">
					<div className="alert alert-warning alert-danger fade show nav-offset-right mb-0" role="alert">
						{message.replace(/Error:\s/g, '')}
						<button type="button" className="close text-light" data-dismiss="alert" aria-label="Close">
							<span onClick={handleClose} aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			}
		</>
	)
}

export default ErrorC