import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CarrierCard from '../CarrierCard'
import CarrierDetails from './CarrierDetails'
import model from '../../model'

const Carrier = () => {
  let { personalId, commercialId } = useParams();
  const recPersonal = useSelector(state => model.selector('carrier.recById', { state, id: personalId }))
  const recCommercial = useSelector(state => model.selector('carrier.recById', { state, id: commercialId }))
  const [isCommercial, setIsCommercial] = useState(recPersonal ? false : true);

  useEffect(() => {
    (async () => {
      try {
        await model.action('app.setLoading', true)
        await model.action('carrier.getById', { carrierId: personalId })
        await model.action('carrier.getById', { carrierId: commercialId })
        await model.action('app.setLoading', false)
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })()
  }, [personalId, commercialId])

  if (!recPersonal && !recCommercial) return <div />
  
  return (
    <>
      <React.Fragment>
        <div className="my-3">
          <div className="container section-container">
            <CarrierCard rec={recPersonal} commercialState={recCommercial} isCommercial={isCommercial} />
            <CarrierDetails rec={recPersonal} recCommercial={recCommercial} isCommercial={isCommercial} setIsCommercial={setIsCommercial} />
            <div className="row justify-content-center">
              <div className="col-md-8 d-none">
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default Carrier;

