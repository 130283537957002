export const onFieldChange = (e, change)=>{
	const target = e.target
	const name = target.name
   
	if (!name) throw new Error('form.onFieldChange error: no name defined for '+target.value)

   let value
   if (target.type === 'checkbox') value = target.checked
   else if (target.type === 'number') value = Number(target.value)
   else if (target.type === 'file') value = e.target.files[0]
   else value = target.value
   change(name, value)
}

export const onFormSubmit = (e, submit)=>{
	e.preventDefault()
	submit()
}