import store from '../../store'
import config from '../../config'
import model from '../index'
import localStorage from '../../utility/localStorage'

import glovebox from '@gloveboxhq/glovebox-sdk-js'

const pendingAgencyKey = 'pendingAgencyCode'

const add = async (rec) => {
	const token = await model.action('app.getUserToken')

	const add = {
		data: {
			...rec,
		}
	}

	const res = await glovebox.req({
		endpoint: 'agencies',
		method: 'post',
		data: add,
		token
	})
	return res.data
}

const associateWithUser = async ({ agencyId, agentId, userId }) => {
	const token = await model.action('app.getUserToken')

	if (agencyId) {
		await glovebox.req({
			endpoint: `users/${userId}/agencies/${agencyId}`,
			method: 'put',
			token
		})
	}

	if (agencyId && agentId) {
		await glovebox.req({
			endpoint: `users/${userId}/agencies/${agencyId}/agents/${agentId}`,
			method: 'put',
			token
		})
	}
}

const connect = async ({ code, userId }) => {
	const parts = model.helper('agency.agencyCodeSplit', { code })

	// if no user logged in, store in localStorage
	const isLoggedIn = await model.action('user.getUserLoggedIn')

	if (!isLoggedIn) {
		localStorage.set(pendingAgencyKey, code)
		return
	}

	if (parts.agencyId && parts.agentId) {
		await associateWithUser({ agencyId: parts.agencyId, agentId: parts.agentId, userId })
	} else {
		await associateWithUser({ agencyId: code, userId })
	}

	// await associateWithUser({ agencyId: code, userId })
}

const getAddressFormatted = ({ address }) => {
	let addy = []
	if (address.street1) addy.push(address.street1)
	if (address.street2) addy.push(address.street2)
	if (address.city) addy.push(address.city)
	if (address.state) addy.push(address.state)
	if (address.zip) addy.push(address.zip)
	return addy.join(', ')
}

const getAgentName = ({ agent }) => {
	let names = []
	if (agent.firstName) names.push(agent.firstName)
	if (agent.lastName) names.push(agent.lastName)
	return names.join(' ')
}

const getPendingAgencyId = async () => {
	return localStorage.get(pendingAgencyKey)
}

const getUserAgencies = async ({ userId }) => {
	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		endpoint: `users/${userId}/agencies?latest=true`,
		method: 'get',
		token
	})

	return res;
}

const associatePendingAgency = async ({ userId }) => {
	const agencyId = await getPendingAgencyId()
	if (!agencyId) return
	await associateWithUser({ agencyId, userId })

	// remove pending agency from localStorage
	localStorage.clear(pendingAgencyKey)
}

const hiJackPHA = (agencyId, agentId) => {
	return `${config.clientWebApp}/?agencyId=${agencyId}-${agentId}`
}

const getById = async ({ agencyId }) => {
	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		endpoint: `agencies/${agencyId}`,
		method: 'get',
		token
	})
	store.dispatch({
		type: 'AGENCY_REC_SET',
		payload: { rec: res.data },
	})
}

const searchAgency = async (searchQuery) => {
	if (!searchQuery) return []

	const res = await glovebox.req({
		endpoint: `agencies?q=${searchQuery}&includeDisabled=true`,
		method: 'get',
	})

	store.dispatch({
		type: 'SEARCH_QUERY_SET',
		payload: { searchQuery },
	})

	store.dispatch({
		type: 'SEARCH_RESULTS_SET',
		payload: { searchResults: res.data },
	})

	return res.data
}

const getAll = async () => {
	const res = await glovebox.req({
		endpoint: 'agencies?includeDisabled=true',
		method: 'get',
	})

	const all = []
	res.data.forEach(rec => {
		all.push(rec.id)
		store.dispatch({
			type: 'AGENCY_REC_SET',
			payload: { rec },
		})
	})

	store.dispatch({
		type: 'AGENCY_ALL_SET',
		payload: { all },
	})

	return store.getState().agency.recs;
}

const getAgents = async ({ agencyId }) => {
	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		endpoint: `agencies/${agencyId}/agents`,
		method: 'get',
		token
	})

	const list = []
	res.data.forEach(rec => {
		list.push(rec.id)
		store.dispatch({
			type: 'AGENCY_AGENT_REC_SET',
			payload: { rec },
		})
	})

	store.dispatch({
		type: 'AGENCY_AGENT_LIST_SET',
		payload: { list, agencyId },
	})

}

const getPhoneFormatted = ({ phone }) => {
	return phone.substr(2, 3) + '-' + phone.substr(5, 3) + '-' + phone.substr(8, 4)
}

const getFeatureFlags = async ({ agencyID, agentID }) => {
	const token = await model.action('app.getUserToken')

	const path = `feature_flags/v1/agency?agency_id=${agencyID}`

	const res = await glovebox.req({
		path,
		method: 'get',
		token,
	})

	let featureFlags = res.feature_flags

	store.dispatch({
		type: 'AGENCY_FEATURE_FLAGS_SET',
		payload: {
			agencyID,
			featureFlags,
		}
	})

	return featureFlags
}

const addFeatureFlag = async ({ agencyID, featureFlag, enabled }) => {
	const token = await model.action('app.getUserToken')

	const path = `feature_flags/v1/agency?agency_id=${agencyID}&flag_id=${featureFlag}&enabled=${enabled}`

	const res = await glovebox.req({
		path,
		method: 'put',
		token
	})

	return res
}

const save = async (rec) => {
	const token = await model.action('app.getUserToken')
	let save = {
		data: { ...rec }
	}

	await glovebox.req({
		endpoint: `agencies/${rec.id}`,
		method: 'put',
		data: save,
		token
	})

	store.dispatch({
		type: 'AGENCY_REC_SET',
		payload: { rec },
	})

	return rec
}

const remove = async (rec) => {
	const token = await model.action('app.getUserToken')

	let save = {
		data: { ...rec }
	}

	await glovebox.req({
		endpoint: `agencies/${rec.id}`,
		method: 'put',
		data: save,
		token
	})

	store.dispatch({
		type: 'AGENCY_REC_REMOVE',
		payload: { rec },
	})

	return rec
}

const removeAgencyConnection = async ({ agencyId, agentId, userId }) => {
	const token = await model.action('app.getUserToken')

	if (!userId) throw new Error('no userId found')

	if (agencyId && agentId) {
		await glovebox.req({
			endpoint: `users/${userId}/agencies/${agencyId}/agents/${agentId}`,
			method: 'delete',
			token
		})
	}
	if (agencyId) {
		await glovebox.req({
			endpoint: `users/${userId}/agencies/${agencyId}`,
			method: 'delete',
			token
		})
	}
}

const toggleAgentFilter = async () => {
	const currentFilter = await store.getState().agency.filterEnabled;

	store.dispatch({
		type: 'AGENCY_FILTER_SET',
		payload: { filterEnabled: !currentFilter },
	})
}

const uploadImage = async ({ agencyId, image }) => {
	const token = await model.action('app.getUserToken')

	const data = new FormData();
	data.append('file', image)
	const headers = {
		'content-type': 'multipart/form-data'
	}
	await glovebox.req({
		endpoint: `agencies/${agencyId}/logo`,
		headers,
		method: 'post',
		data,
		token
	})

	await getById({ agencyId })
}

const getAgencyState = () => store.getState().agency;


model.registerActions('agency', {
	add,
	addFeatureFlag,
	associatePendingAgency,
	connect,
	getAddressFormatted,
	getAgentName,
	getAgents,
	getById,
	getAll,
	getFeatureFlags,
	getPhoneFormatted,
	getUserAgencies,
	hiJackPHA,
	remove,
	removeAgencyConnection,
	save,
	searchAgency,
	getAgencyState,
	toggleAgentFilter,
	uploadImage,
})