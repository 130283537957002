import React, { useState } from 'react'

const ConnectLink = ({ connectLabel, children }) => {
	return (
		<li>
			<input id={`group-item`} type="checkbox" hidden />
			<label className='section-title' htmlFor={`group-item`}>
				<p className='mr-5 text-base'>{connectLabel}</p>
				<span><img src='/img/arrow-down.svg' alt="down arrow" /></span>
			</label>
			<ul className={`group-list`} alt="">
				{children}
			</ul>
		</li>
	)
}

const AgencyLink = ({ rec }) => {
	const [showCopied, setShowCopied] = useState(false);
	const link = rec.connectURL;

	const handleCopyClick = () => {

		navigator.clipboard.writeText(link);

		setShowCopied(true);

		setTimeout(() => {
			setShowCopied(false)
		}, 3000)
	}

	return (
		<>
			<div className="row mb-3 drop-down justify-content-between mt-4">
				<ul className="col-md-12 drop__list">
					<ConnectLink
						connectLabel={`Agency Connect Link`}
					>
						<li className="listItem">
							<div className="detail-row row">
								<input className="form-control form-control-lg col-sm-9 mr-3" type="text" readOnly="readOnly" value={link} />
								<button className="btn btn-outline-primary col-sm-2 agency-connect" onClick={() => handleCopyClick()}>
									{showCopied ?
										<React.Fragment>
											<i className="far fa-clipboard-check text-primary mr-2" title="Copied"></i>
											Copied
										</React.Fragment>
										:
										<React.Fragment>
											<i className="far fa-clipboard mr-3" title="Copy link"></i>
											Copy
										</React.Fragment>
									}
								</button>
							</div>
						</li>
					</ConnectLink>
				</ul>
			</div>
		</>
	)
}

export default AgencyLink