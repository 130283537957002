import React, { useState } from 'react'
import CarrierCardEdit from './CarrierCardEdit'

const CarrierCard = ({ rec, isCommercial, commercialState }) => {
  const [showEditOnce, setShowEditOnce] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const imageUrl = !isCommercial ? rec?.logo : commercialState?.logo
  const cardClass = `agency-card d-block ${!isCommercial ? rec?.theme : commercialState?.theme}`
  const style = { backgroundImage: `url(${imageUrl})` || '', margin: '3rem' }

  const handleLoadError = () => {
    if (!showEditOnce) {
      setShowEditOnce(true)
      setEditForm(true)
    }
  }

  return (
    <>
      {editForm ?
        <CarrierCardEdit handleClose={() => setEditForm(false)} handleComplete={() => setEditForm(false)} rec={rec} commercialState={commercialState} isCommercial={isCommercial} />
        :
        <div className="row justify-content-center mb-3">
          <div className="col-md-8">
            <div onClick={() => setEditForm(true)} className={cardClass}>
              <div className="agency-card-holder d-flex align-items-center">
                <div className="card-close">
                  <i className="far fa-pen" aria-hidden="true"></i>
                </div>
                <div className="text-center w-100">
                  <div className="agency-card-icon-holder" style={style} />
                  <img className="agency-card-icon d-none" onError={handleLoadError} src={imageUrl} alt="agency icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default CarrierCard