const getPhoneFormatted = ({ phone }) => {
	return phone.substr(2, 3) + '-' + phone.substr(5, 3) + '-' + phone.substr(8, 4)
}

const formatDate = (date) => {
	let d = new Date(date);
	let month = d.getMonth() + 1;
	let day = d.getDate();
	let year = d.getFullYear();

	return `${month}/${day}/${year}`;
}

const PhoneFormattedPolicyHolder = (phone) => {
	if (!phone || typeof phone !== 'string') return phone
	if (phone.substr(0, 1) === '+') {
		return phone.substr(2, 3) + '-' + phone.substr(5, 3) + '-' + phone.substr(8, 4)
	}
	return phone
}

const capitalize = (str) => {
	if (!str || str.length < 1) return str
	return str.charAt(0).toUpperCase() + str.slice(1)
}

const getUrlFromStr = (str) => {
	if (!str || str.length < 1) return null
	if (str.indexOf('http') === 0) return str
	return `https://${str}`
}

const getQueryParamByName = (name, url) => {
    if (!url) url = window.location.href;

    name = name.replace(/\\/g, '\\\\').replace(/[[\]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'g');

    const results = regex.exec(url);
    if (!results) return null;

    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getSearchQueryNormalized = (query) => {
	return query.toLowerCase().trim().replace(/-/g, '')
}

const objToQuery = (obj) => {
	return new URLSearchParams(obj).toString()
}

const str = {
	capitalize,
	getQueryParamByName,
	getPhoneFormatted,
	getSearchQueryNormalized,
	getUrlFromStr,
	PhoneFormattedPolicyHolder,
	objToQuery,
	formatDate
}

export default str