let listeners = {}, helpers = {}, selectors = {}

const action = async (action, payload)=>{
	if (!listeners[action]) throw new Error(`model.action: no action subscribed for ${action}`)
	return await listeners[action](payload)
}
const helper = (helper, payload)=>{
	if (!helpers[helper]) throw new Error(`model.helper: no helper subscribed for ${helper}`)
	return helpers[helper](payload)
}
const selector = (selector, payload)=>{
	if (!selectors[selector]) throw new Error(`model.selector: no selector subscribed for ${selector}`)
	return selectors[selector](payload)
}

const registerActions = (model, actions = {})=>{
	if (!model) throw new Error('registerActions no model')
	for (let i in actions){
		listeners[`${model}.${i}`] = actions[i]
	}
}
const registerHelpers = (model, register = {})=>{
	if (!model) throw new Error('registerHelpers no model')
	for (let i in register){
		helpers[`${model}.${i}`] = register[i]
	}
}
const registerSelectors = (model, register = {})=>{
	if (!model) throw new Error('registerSelectors no model')
	for (let i in register){
		selectors[`${model}.${i}`] = register[i]
	}
}

const model = {
	action,
	helper,
	selector,
	registerActions,
	registerHelpers,
	registerSelectors,
}

export default model