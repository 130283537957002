import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const CarrierList = ({ list }) => {
  const navigate = useNavigate()
  const listSizeMax = 20
  
    // Group the carriers by their groupID
    const groupedCarriers = list.reduce((groups, carrier) => {
      const groupID = carrier.groupID
      if (!groups[groupID]) {
        groups[groupID] = []
      }
      groups[groupID].push(carrier)
      return groups
    }, {})
  
  // Create a map with unique carriers per groupID and their IDs separated by division
  const carriersMap = Object.entries(groupedCarriers).reduce((acc, [groupID, group]) => {
    const personalCarriers = group.filter((carrier) => carrier.division === 'personal');
    const commercialCarriers = group.filter((carrier) => carrier.division === 'commercial');
    const carrier = personalCarriers.length > 0 ? personalCarriers[0] : group[0];
    acc[groupID] = {
      carrier,
      personalID: personalCarriers.length > 0 ? personalCarriers[0].id : null,
      commercialID: commercialCarriers.length > 0 ? commercialCarriers[0].id : null,
    };
    return acc;
  }, {});

  const carriers = Object.values(carriersMap);
  
  return (
    <div className="list">
      {carriers.map(({ carrier, personalID, commercialID }, i) => {
        const imageUrl = carrier.logo;
        const className = `agency-card agency-card-sm ${carrier.theme === 'dark' ? 'dark' : ''}`;
        const link = personalID && commercialID
          ? `/personal/${personalID}/commercial/${commercialID}`
          : personalID
          ? `/personal/${personalID}/carrier/${carrier.groupID}`
          : `/commercial/${commercialID}/carrier/${carrier.groupID}`;
        const style = { backgroundImage: `url(${imageUrl})` || '', margin: '0.5rem' };

        return (
          <React.Fragment key={carrier.groupID}>
            {i > listSizeMax ? (
              <div />
            ) : (
              <div onClick={() => navigate(link)} className="agency-row row">
                <div className="col-sm-4">
                  <div className={className}>
                    <div className="agency-card-holder">
                      <div className="agency-card-icon-holder" style={style}></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <Link className="agency-row-name" to={link}>
                    {carrier.name}
                  </Link>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default CarrierList
