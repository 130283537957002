import React from 'react'

const AgencyAgentCard = ({ rec }) => {
    const imageUrl = !rec.agents.length ? rec.logo : rec.agents[rec.agents.length - 1].logo
    const theme = !rec.agents.length ? rec.theme : rec.agents[rec.agents.length - 1].theme
    return (
        <>
                <div className="row justify-content-center mb-3">
                    <div className="col-md-8">
                        <div className={`agency-card d-block ${theme}`}>
                            <div className="agency-card-holder d-flex align-items-center">
                                <div className="text-center w-100">
                                    <div className="agency-card-icon-holder" style={{ backgroundImage: `url(${imageUrl})` || '', margin: '3rem' }} />
                                    <img className="agency-card-icon d-none" src={imageUrl} alt="agency icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default AgencyAgentCard