import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Login from './Login'
import Verify from './Verify'

import model from '../../model'

const Auth = () => {
	const navigate = useNavigate()
	const [method, setMethod] = useState('phone')
	const [showPage, setShowPage] = useState('login');
	const [recState, setRecState] = useState({
		phone: '',
		email: '',
		pin: ''
	});

	const handleAuthChange = (name, value) => {
		const rec = {
			...recState,
			[name]: value,
		}
		setRecState(rec);
	}

	const handleLogin = async () => {
		const { phone, email } = recState;

		if (method === 'email' && !email) return model.action('app.setError', "Enter an email")
		else if (method !== 'email' && !phone) return model.action('app.setError', "Enter a phone number")

		const send = method === 'email' ? { email } : { phone }

		if (method === 'email' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) === false) {
			return model.action('app.setError', "Enter a valid email")
		}
		try {
			await model.action('app.setLoading', true)
			// if there is already a user, just login, else create user first
			await model.action('user.login', send)
			await model.action('app.setLoading', false)
			setShowPage('verify')

		} catch (e) {
			await model.action('app.setLoading', false)
			await model.action('app.setError', e)
		}
	}

	const handleVerify = async () => {
		const { email, phone, pin } = recState

		if (method === 'email' && !email) return model.action('app.setError', "Enter an email")
		else if (method !== 'email' && !phone) return model.action('app.setError', "Enter a phone number")

		const send = method === 'email' ? { email } : { phone }

		await model.action('app.setError', null)
		try {
			await model.action('app.setLoading', true)
			await model.action('user.loginVerify', { ...send, pin })
			await model.action('app.setLoading', false)
			navigate('/')
		} catch (e) {
			await model.action('app.setLoading', false)
			await model.action('app.setError', e)
		}
	}

	const changeMethod = () => {
		if (method === 'phone') {
			setMethod('email')
		} else {
			setMethod('phone')
		}
	}

	return (
		<div>
			{showPage === 'verify' ? <Verify handleChange={handleAuthChange} handleSubmit={handleVerify} rec={recState} />
				: <Login handleChange={handleAuthChange} handleSubmit={handleLogin} rec={recState} changeMethod={changeMethod} method={method} />}
		</div>
	)
}

export default Auth