import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
    BuildingStorefrontIcon,
    UserCircleIcon,
    UserIcon,
    BuildingOffice2Icon,
} from '@heroicons/react/24/outline';

const navigation = [
    { name: 'Agencies', href: '/', icon: BuildingStorefrontIcon },
    { name: 'Carriers', href: '/carriers', icon: BuildingOffice2Icon },
    { name: 'Users', href: '/users', icon: UserIcon },
];

const logOutNavItem = { name: 'Log Out', href: '/account', icon: UserCircleIcon };

export default function SideNav() {
    const location = useLocation();
    const [navItems, setNavItems] = useState(navigation);
    const [logOutCurrent, setLogOutCurrent] = useState(false);

    useEffect(() => {
        const updatedItems = navigation.map(item => ({
            ...item,
            current: location.pathname === item.href,
        }));
        setNavItems(updatedItems);

        setLogOutCurrent(location.pathname === logOutNavItem.href);
    }, [location.pathname]);

    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 h-100 px-6">
            <div className="flex h-16 shrink-0 items-center">
                <img
                    className="h-8 w-auto"
                    src="/img/glovebox-logo.svg"
                    alt="Your Company"
                />
            </div>
            <nav className="flex flex-1 flex-col justify-between">
                <ul className="flex flex-col gap-y-7">
                    <li>
                        <ul className="-mx-2 space-y-1">
                            {navItems.map((item) => (
                                <li key={item.name}>
                                    <NavLink
                                        to={item.href}
                                        className={`text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${item.current ? 'bg-gray-800' : ''}`}
                                    >
                                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                        {item.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
                <ul className="pb-5">
                    <li>
                        <NavLink
                            to={logOutNavItem.href}
                            className={`text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold ${logOutCurrent ? 'bg-gray-800' : ''}`}
                        >
                            <logOutNavItem.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            {logOutNavItem.name}
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

