import React, { useState } from 'react'

import model from '../../model'
import { onFieldChange, onFormSubmit } from '../../utility/form'

const CarrierCardEdit = ({ handleComplete, rec, handleClose, commercialState, isCommercial}) => {
    const [form, setForm] = useState({
        image: '',
        theme: rec?.theme || 'light'
    })
    const [commercialForm, setCommercialForm] = useState({
        image: '',
        theme: commercialState?.theme || 'light'
    })

    const handleSubmit = async () => {
        await model.action('app.setError', null)
        try {
            await model.action('app.setLoading', true)
            if (form.image) {
                await model.action('carrier.uploadImage', { carrierId: rec.id, image: form.image })
            }
            if (commercialForm.image) {
                await model.action('carrier.uploadImage', { carrierId: commercialState.id, image: commercialForm.image })
            }
            await model.action('app.setLoading', false)
            await handleComplete()
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handleChange = (name, value) => {
        const formState = {
            ...form,
            [name]: value,
        }

        setForm(formState);
    }

    const handleCommercialChange = (name, value) => {
        const commercialFormState = {
            ...commercialForm,
            [name]: value,
        }
        setCommercialForm(commercialFormState);
    }
    
    return (
        <form className="row justify-content-center mb-3" onSubmit={e => onFormSubmit(e, handleSubmit)}>
            <div className="col-md-8">
                <div className="section-title">
                    Carrier Card
                    <span className="section-close" onClick={handleClose}><i className="far fa-times"></i></span>
                </div>
                {!isCommercial ? 
                <div className="form-row">
                    <label>Image</label>
                    <input
                        className="form-control"
                        name="image"
                        accept=".jpg,.png,.gif,image/jpeg,image/gif,image/png"
                        onChange={e => onFieldChange(e, handleChange)}
                        placeholder="Image file"
                        type="file" />
                    <div className="form-hint">
                        Image should be 450x450 and in png format
                    </div>
                </div>
                :
                <div className="form-row">
                <label>Image</label>
                <input
                    className="form-control"
                    name="image"
                    accept=".jpg,.png,.gif,image/jpeg,image/gif,image/png"
                    onChange={e => onFieldChange(e, handleCommercialChange)}
                    placeholder="Image file"
                    type="file" />
                <div className="form-hint">
                    Image should be 450x450 and in png format
                </div>
            </div>
            }
                <div className="form-actions">
                    <button className="btn btn-primary btn-block">Save Card</button>
                </div>
            </div>
        </form>
    )
}

export default CarrierCardEdit;
