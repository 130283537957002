import React from 'react'

const Pagination = ({ perPage, total, paginate }) => {
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(total / perPage); i++) {
        pageNumbers.push(i)
    }

    return (
        <nav className="mt-5 mb-5">
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className="page-item cursor-pointer">
                        <div onClick={() => paginate(number)} className="page-link">
                            {number}
                        </div>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Pagination;
