import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import model from '../../model'

const Account = () => {
  const user = useSelector(state => model.selector('user.rec', state));
  const navigate = useNavigate()
  const linkLogin = '/auth/login'
  const linkCreateAccount = '/auth/join'

  const handleLogout = async () => {
    try {
      await model.action('app.setLoading', true)
      await model.action('user.logout')
      await model.action('app.setLoading', false)
      navigate('/')
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  return (
    <div className="my-3">
      <div className="container section-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h3 className="app-title-bar">
              Account
            </h3>
            {!user && (
              <div className="list">
                <Link to={linkLogin} className="item interactive">
                  Login
                </Link>
              </div>
            )}
            {!user && (
              <div className="list">
                <Link to={linkCreateAccount} className="item interactive">
                  Create Account
                </Link>
              </div>
            )}
            {user && (
              <div className="list">
                <div onClick={handleLogout} className="item interactive">
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account;
