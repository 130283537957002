import { applyMiddleware, combineReducers, createStore } from 'redux'

import {reducer as agency} from './model/agency/reducer'
import {reducer as app} from './model/app/reducer'
import {reducer as carrier} from './model/carrier/reducer'
import {reducer as user} from './model/user/reducer'

export const reducers = combineReducers({
	agency,
	app,
	carrier,
	user,
})

const middleware = applyMiddleware()

export default createStore(reducers, middleware)