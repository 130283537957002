import React from 'react'
import Modal from '../Modal'

const AgencyDeleteModal = ({ handleClose, handleConfirm }) => {
  return (
    <Modal>
      <div className="modal-header text-dark">
        <h5 className="modal-title">Delete agency?</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <div className="modal-body text-left">
        <p className="mb-0">Are you sure you want to delete this agency?</p>
      </div>
      <div className="modal-footer color-action">
        <button onClick={handleConfirm} className="btn btn-outline-primary">Yes, Delete</button>
      </div>
    </Modal>
  )
}

export default AgencyDeleteModal