const state = {
	listActive:null,
	recs:{},
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case 'CARRIER_LIST_ACTIVE_SET':{
			const {listActive} = action.payload
			return { ...st, listActive }
		}
		case 'CARRIER_REC_SET':{
			const {rec} = action.payload
			const recs = {
				...st.recs,
				[rec.id]: rec
			}
			return { ...st, recs }
		}
		default:
			return st
  }
}