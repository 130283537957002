
// functions adapted from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

const get = ({name})=>{
	var nameEQ = name + "="
	var ca = document.cookie.split(';')
	for(var i=0;i < ca.length;i++){
		var c = ca[i]
		while (c.charAt(0) === ' ') c = c.substring(1,c.length)
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
	}
	return null;
}

const remove = ({name})=>{   
	document.cookie = name+'=;Max-Age=-99999999;'
}

const set = ({name, value, days, domain, sameSite, secure})=>{

	let parts = []
	parts.push(`${name}=${value}`)

	if (days){
		var date = new Date()
		date.setTime(date.getTime() + (days*24*60*60*1000))
		parts.push(`expires=${date.toUTCString()}`)
	}
	if (domain){
		parts.push(`domain=${domain}`)
	}
	if (sameSite){
		parts.push(`samesite=${sameSite}`)
	}
	if (secure){
		parts.push('secure')
	}

	parts.push('path=/')

	document.cookie = parts.join(';')
}

const cookie = {
	get,
	remove,
	set,
}

export default cookie