import React from 'react'
import { onFieldChange, onFormSubmit } from '../../utility/form'

const Verify = ({ handleSubmit, handleChange, rec }) => {
	return (
		<React.Fragment>
			<form onSubmit={e => onFormSubmit(e, handleSubmit)}>
				<div className="container section-container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h3 className="app-title-bar">
								Verify your phone number
							</h3>
							<div className="section-title">
								Enter the texted pin below
							</div>
							<div className="form-row">
								<label>Pin</label>
								<input
									autoComplete="one-time-code"
									className="form-control form-control-lg"
									name="pin"
									onChange={e => onFieldChange(e, handleChange)}
									placeholder="******"
									type="pin"
									value={rec.pin} />
							</div>
							<div className="form-actions">
								<button className="btn btn-primary btn-block">Verify</button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div className="content-stretch" />
		</React.Fragment>
	)
}

export default Verify
