import React from 'react'
import { NavLink } from 'react-router-dom'
import { onFieldChange, onFormSubmit } from '../../utility/form'

const Login = ({ handleSubmit, handleChange, rec, changeMethod, method }) => {

	const renderInput = () => {
		switch (method) {
			case 'email':
				return (
					<div className="form-row">
						<label>Email</label>
						<input
							className="form-control form-control-lg"
							name="email"
							onChange={e => onFieldChange(e, handleChange)}
							placeholder="Enter Email..."
							type="text"
							value={rec.email} />
					</div>
				)
			default:
				return (
					<div className="form-row">
						<label>Phone</label>
						<input
							className="form-control form-control-lg"
							name="phone"
							onChange={e => onFieldChange(e, handleChange)}
							placeholder="Enter Phone Number..."
							type="phone"
							value={rec.phone} />
					</div>
				)
		}
	}

	return (
		<React.Fragment>
			<form onSubmit={e => onFormSubmit(e, handleSubmit)}>
				<div className="container section-container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h3 className="app-title-bar pb-5">
								Login to GloveBox Admin
							</h3>
							<div className="row justify-content-center">
								<div className="col-md-12">
									<div className="d-flex">
										<div className={`flex-grow-1 ${method !== 'email' ? 'active-bg' : ''}`}>
											<NavLink to={'/auth/phone'} onClick={() => changeMethod()} className={`phone-login`} style={{ textDecoration: 'none' }}>
												<div className={`phone-text ${method !== 'email' ? 'text-active' : ''}`}>PHONE</div>
												{method !== 'email' && <div className={`active-login`} />}
											</NavLink>
										</div>
										<div className={`flex-grow-1 ${method === 'email' ? 'active-bg' : ''}`}>
											<NavLink to={'/auth/email'} onClick={() => changeMethod()} className={`phone-login`} style={{ textDecoration: 'none' }}>
												<div className={`phone-text ${method === 'email' ? 'text-active' : ''}`}>EMAIL</div>
												{method === 'email' && <div className={`active-login`} />}
											</NavLink>
										</div>
									</div>
								</div>
							</div>
							{renderInput()}
							<div className="form-actions">
								<button className="btn btn-primary btn-block">Login</button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<div className="content-stretch bg-white" />
		</React.Fragment>
	)
}

export default Login