import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import model from '../../model'
import CarrierList from './CarrierList'
import { onFieldChange } from '../../utility/form'

const Carriers = () => {
  const history = createBrowserHistory();
  const listActive = useSelector(state => model.selector('carrier.listActive', state));
  const [search, setSearch] = useState('');
  const linkAdd = '/carriers/add';

  useEffect(() => {
    (async () => {
      try {
        await model.action('app.setLoading', true)
        await model.action('carrier.getList')
        await model.action('app.setLoading', false)
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })();
  }, []);

  useEffect(() => {
    if (history.action === 'POP') {
      const lastSearch = sessionStorage.getItem('searchCarriers') || ''
      setSearch(lastSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterSearch = (list) => {
    if (!list) return []
    const searchLower = search.toLowerCase()
    return list.filter((item) => item.name.toLowerCase().includes(searchLower))
  }

  const handleChange = (name, value) => {
    sessionStorage.setItem('searchCarriers', value);
    setSearch(value);
  };

  return (
    <div className="my-3">
      <div className="container section-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h3 className="app-headline-bar">Carriers</h3>
            <div className="app-headline-bar d-flex align-items-center">
              <i className="far fa-search"></i>
              <input
                autoFocus
                className="flex-grow-1 form-control mx-3"
                onChange={e => onFieldChange(e, handleChange)}
                name="search"
                placeholder="search for a carrier"
                type="text"
                value={search}
              />
              <Link to={linkAdd} className="float-right hover-highlight">
                <i className="far fa-plus-square"></i>
              </Link>
            </div>
            <CarrierList list={filterSearch(listActive)} search={search} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carriers;

