import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import AgencyLink from './AgencyLink'
import model from '../../model'
import NewToggle from '../NewToggle'

const AgencyDetails = ({ rec }) => {
  const featureFlags = useSelector(state => model.selector('agency.featureFlagsGet', { state, agencyId: rec.id }))
  const [showCopyPha, setShowCopyPha] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [recState] = useState({ ...rec });
  const [enabled, setEnabled] = useState(false);
  const appLink = model.helper('agency.getAppLink', { agencyId: rec.id })

  useEffect(() => {
    const featureFlag = featureFlags?.find(flag => flag.feature_flag.id === 'client_email_rollout');

    if (featureFlag) {
      setEnabled(featureFlag.feature_flag.enabled);
    }

  }, [featureFlags]);

  const toggleFeatureFlag = async (value) => {
    try {
      await model.action('app.setLoading', true)
      await model.action('agency.addFeatureFlag', { agencyID: rec.id, featureFlag: 'client_email_rollout', enabled: value });
      await model.action('agency.getFeatureFlags', { agencyID: rec.id })
      await model.action('app.setLoading', false)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  const handleCopyPHA = (phaLink, i) => {
    navigator.clipboard.writeText(phaLink)

    setShowCopyPha(true);
    setShowButton(i)

    setTimeout(() => {
      setShowCopyPha(false);
      setShowButton(false);
    }, 3000)
  }

  const renderAgents = () => {
    return (
      <div className="my-3">
        <div className="container section-container">
          <div className="row justify-content-center">
            <h3 className="app-title-bar">
              Agents
            </h3>
            <div className="col-md-12">
              {recState.agents.length > 0 ? recState.agents.map((agent, i) => {
                const phaLink = model.helper('agency.getAgencyPhaLink', { agencyId: agent.agencyID, agentId: agent.id })
                return (
                  <div key={agent.id} className="agency-row row">
                    <div className="col-sm-4">
                      <div className={`agency-card agency-card-sm ${rec.theme === 'dark' ? 'dark' : ''}`}>
                        <a href={agent.logo + "?force=true"}>
                          <div className="agency-card-holder">
                            <div className="agency-card-icon-holder" style={{ backgroundImage: `url(${agent.logo})` || '', margin: '0.5rem' }}>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="agency-row-name ml-5 mt-3 text-center">{agent.firstName} {agent.lastName}
                      </div>
                      <div className="agency-row-name ml-5 agency-code text-center">{`${agent.agencyID}-${agent.id}`}
                      </div>
                    </div>
                    <div className="col-sm-4 align-items-center">
                      <a className="btn float-right hq-agent btn-outline-primary mt-3 mb-2" href={model.helper('agency.hiJackAgentUrl', { agencyId: agent.agencyID, agentId: agent.id })}
                        target="_blank" rel="noopener noreferrer">OPEN HQ</a>
                      <button className="btn float-right" onClick={() => handleCopyPHA(phaLink, i)}>
                        {showCopyPha && showButton === i ?
                          <div className="btn btn-outline-primary">
                            <i id={i} className="far fa-clipboard-check text-primary mr-4" title="Copied"></i>
                            Copied Link
                          </div>
                          :
                          <div className="btn btn-outline-primary">
                            <i id={i} className="far fa-clipboard mr-2" title="Copy link"></i>
                            Copy Elite Link
                          </div>
                        }
                      </button>
                    </div>
                  </div>
                )
              }) : <div className="text-center"> No agents found </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="flex flex-col items-center mt-12 w-full">
      <div className="flex w-8/12 justify-between items-center p-4 mb-3">
          <label className="text-left text-base">Agency HQ</label>
          <a className="btn btn-outline-primary btn-sm p-3" href={model.helper('agency.getHijackUrl', rec)}
            target="_blank" rel="noopener noreferrer">HQ</a>
        </div>
        <hr className="border-t border-white w-8/12 mb-3" />
        <div className="flex w-8/12 justify-between items-center p-4 mb-3">
          <label className="text-left text-base">Client Email Rollout</label>
          <NewToggle enabled={enabled} onToggle={toggleFeatureFlag} setEnabled={setEnabled} />
        </div>
        <hr className="border-t border-white w-8/12 mb-3" />
        <div className="flex w-8/12 justify-between items-center p-4 mb-3">
          <label className="text-left text-base">Login Link</label>
          <a href={appLink} id="glovebox-btn" target="_blank" rel="noopener noreferrer"
            onMouseOver={() => { var btn = document.getElementById('glovebox-btn'); btn.style.backgroundColor = '#EDEFF1'; }}
            onMouseOut={() => { var btn = document.getElementById('glovebox-btn'); btn.style.backgroundColor = '#FFF'; }}
            className="border border-gray-900 bg-white rounded p-1 cursor-pointer">
            <div className="bg-center bg-no-repeat bg-contain h-10 w-44"
              style={{ backgroundImage: `url(${model.helper('agency.getButtonImgLink')})` }} />
          </a>
        </div>
        <hr className="border-t border-white w-8/12 mb-3" />
        <div className="flex w-8/12 justify-between items-center p-4 mb-3">
          <label className="text-left text-base">Download Logo</label>
          <a className="btn btn-outline-primary btn-sm p-2" href={rec.logo + "?force=true"}
            target="_blank" rel="noopener noreferrer" download>
            Download Agency Logo
          </a>
        </div>
        <hr className="border-t border-white w-8/12 mb-3" />
      </div>
      <div className="flex flex-col items-center w-full">
        <div className="w-8/12">
          <AgencyLink rec={rec} />
        </div>
        <hr className="border-t border-white w-8/12 mb-3" />
      </div>
      <div className="flex flex-col items-center mt-3 w-full">
        <div className="w-8/12">
          {renderAgents()}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AgencyDetails