import React from 'react'

const Modal = ({ children }) => {
	// add class d-block
	return (
		<div className="modal fade show d-block" tabIndex="-1" role="dialog">
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					{children}
				</div>
			</div>
		</div>
	)
}

export default Modal
