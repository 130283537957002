import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import model from '../../model'

import { onFieldChange, onFormSubmit } from '../../utility/form'

const AgencyAdd = () => {
  const navigate = useNavigate()
  const [rec, setRec] = useState({
    name: '',

    theme: 'light',

    customerServicePhone: '',
    customerServiceEmail: '',

    salesPhone: '',
    salesEmail: '',

    // address
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',

    website: '',
    facebook: '',
    instagram: '',
    linkedIn: '',
    twitter: '',
    google: '',
    yelp: '',
    review: '',

    requestQuoteURL: '',
    requestServiceURL: '',
    setAppointmentURL: '',
  });

  const handleChange = (name, value) => {
    setRec({ ...rec, [name]: value });
  }

  const handleSubmit = async () => {

    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      const add = {
        enabled: true,

        name: rec.name || null,
        theme: rec.theme || null,

        customerServicePhone: rec.customerServicePhone || null,
        customerServiceEmail: rec.customerServiceEmail || null,

        salesPhone: rec.salesPhone || null,
        salesEmail: rec.salesEmail || null,

        address: {
          street1: rec.street1 || null,
          street2: rec.street2 || null,
          city: rec.city || null,
          state: rec.state || null,
          zip: rec.zip || null,
        },

        website: rec.website || null,
        facebook: rec.facebook || null,
        instagram: rec.instagram || null,
        linkedIn: rec.linkedIn || null,
        twitter: rec.twitter || null,
        google: rec.google || null,
        yelp: rec.yelp || null,
        review: rec.review || null,

        requestQuoteURL: rec.requestQuoteURL || null,
        requestServiceURL: rec.requestServiceURL || null,
        setAppointmentURL: rec.setAppointmentURL || null,
      }

      const added = await model.action('agency.add', add)
      await model.action('app.setLoading', false)

      const next = `/agency/${added.id}`
      navigate(next)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  return (
    <form className="my-3" onSubmit={e => onFormSubmit(e, handleSubmit)}>
      <div className="container section-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h3 className="app-title-bar">
              Agency Add
            </h3>
            <div className="section-title">
              Action Links
            </div>
            <div className="form-row">
              <label>Request Quote</label>
              <input
                className="form-control"
                name="requestQuoteURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Request Quote URL"
                type="text"
                value={rec.requestQuoteURL} />
            </div>
            <div className="form-row">
              <label>Request Service</label>
              <input
                className="form-control"
                name="requestServiceURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Request Service URL"
                type="text"
                value={rec.requestServiceURL} />
            </div>
            <div className="form-row">
              <label>Set Appointment URL</label>
              <input
                className="form-control"
                name="setAppointmentURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Set Appointment URL"
                type="text"
                value={rec.setAppointmentURL} />
            </div>
            <div className="section-title mt-3">
              Agency Details
            </div>
            <div className="form-row">
              <label>Name</label>
              <input
                className="form-control"
                name="name"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Name"
                type="text"
                value={rec.name} />
            </div>
            <div className="form-row">
              <label>Theme</label>
              <div className="form-control">
                <div className="btn-group">
                  <div className={`btn ${rec.theme === 'light' ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('theme', 'light')}>
                    Light
                  </div>
                  <div className={`btn ${rec.theme === 'dark' ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('theme', 'dark')}>
                    Dark
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <label>Customer Service Phone</label>
              <input
                className="form-control"
                name="customerServicePhone"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="555-555-5555"
                type="phone"
                value={rec.customerServicePhone} />
            </div>
            <div className="form-row">
              <label>Customer Service Email</label>
              <input
                className="form-control"
                name="customerServiceEmail"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="jon@company.com"
                type="email"
                value={rec.customerServiceEmail} />
            </div>
            <div className="form-row">
              <label>Sales Phone</label>
              <input
                className="form-control"
                name="salesPhone"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="555-555-5555"
                type="phone"
                value={rec.salesPhone} />
            </div>
            <div className="form-row">
              <label>Sales Email</label>
              <input
                className="form-control"
                name="salesEmail"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="jon@company.com"
                type="email"
                value={rec.salesEmail} />
            </div>
            <div className="section-title mt-3">
              Address
            </div>

            <div className="form-row">
              <label>Street</label>
              <input
                className="form-control"
                name="street1"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Street 1"
                type="text"
                value={rec.street1} />
              <label></label>
              <input
                className="form-control"
                name="street2"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Street 2"
                type="text"
                value={rec.street2} />
            </div>
            <div className="form-row">
              <label>City</label>
              <input
                className="form-control"
                name="city"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="City"
                type="text"
                value={rec.city} />
            </div>
            <div className="form-row">
              <label>State</label>
              <input
                className="form-control"
                name="state"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="State"
                type="text"
                value={rec.state} />
            </div>
            <div className="form-row">
              <label>Zip</label>
              <input
                className="form-control"
                name="zip"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Zip"
                type="text"
                value={rec.zip} />
            </div>
            <div className="section-title mt-3">
              Media
            </div>
            <div className="form-row">
              <label>Website</label>
              <input
                className="form-control"
                name="website"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Website"
                type="text"
                value={rec.website} />
            </div>
            <div className="form-row">
              <label>Facebook</label>
              <input
                className="form-control"
                name="facebook"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Facebook"
                type="text"
                value={rec.facebook} />
            </div>
            <div className="form-row">
              <label>Instagram</label>
              <input
                className="form-control"
                name="instagram"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Instagram"
                type="text"
                value={rec.instagram} />
            </div>
            <div className="form-row">
              <label>LinkedIn</label>
              <input
                className="form-control"
                name="linkedIn"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="LinkedIn"
                type="text"
                value={rec.linkedIn} />
            </div>
            <div className="form-row">
              <label>Twitter</label>
              <input
                className="form-control"
                name="twitter"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Twitter"
                type="text"
                value={rec.twitter} />
            </div>
            <div className="form-row">
              <label>Google</label>
              <input
                className="form-control"
                name="google"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Google"
                type="text"
                value={rec.google} />
            </div>
            <div className="form-row">
              <label>Yelp</label>
              <input
                className="form-control"
                name="yelp"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Yelp"
                type="text"
                value={rec.yelp} />
            </div>
            <div className="form-row">
              <label>Review</label>
              <input
                className="form-control"
                name="review"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Review"
                type="text"
                value={rec.review} />
            </div>
            <div className="form-actions mt-4">
              <button className="btn btn-primary btn-block">Create Agency</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AgencyAdd