import React, { useState } from 'react'
import Modal from '../Modal'
import { onFieldChange } from '../../utility/form'

const ModalPurge = ({ handleClose, handleSubmit, headlineText, questionText }) => {
    const match = 'starwars'
    const [disabled, setDisabled] = useState(true)
    const [value, setValue] = useState('')

    const enableBtn = (name, value) => {
        setValue(value);
        if (value === match) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }
    return (
        <Modal>
            <div className="modal-header text-dark">
                <h5 className="modal-title">{headlineText}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <i className="fal fa-times"></i>
                </button>
            </div>
            <div className="modal-body text-center">
                <p className="mb-0">{questionText}</p>
                <p>Please confirm by typing the word 'starwars' in the input box below. </p>
            </div>
            <div>
                <input autoFocus type="text" className="form-control text-dark purge-input mb-3" value={value} name="value" onChange={e => onFieldChange(e, enableBtn)} />
            </div>
            <div className="modal-footer color-action">
                <button disabled={disabled} onClick={handleSubmit} className="btn btn-outline-danger">Yes, Delete</button>
            </div>
        </Modal>
    )
}

export default ModalPurge