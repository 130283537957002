import React from 'react'
import { Link } from 'react-router-dom'
import PolicyTypeIcon from '../PolicyTypeIcon'
import model from '../../model'
import Str from '../../utility/str'

const SearchPolicyholder = ({ policyholder, searchQuery }) => {
	const displays = model.helper('user.getPolicyholderDisplays', { policyholder, searchQuery });

	if (!displays) return <div />

	return (
		<>
			{policyholder && policyholder.user && policyholder.user.id ?
				<>
					<hr className="bg-white m-0 p-0" />
					<div className="search-result-policyholder">
						<Link to={`/users/${policyholder.user.id}`}>
							<div className="search-result-top">
								<div className="search-result-name">
									{displays.title} {(policyholder && policyholder.user && policyholder.user.id) && <span className="search-result-name-user-id">- ID: {policyholder.user.id}</span>}
								</div>
								<div className="search-result-action">
								{(policyholder.user.last_access) &&
									<span className="search-result-last-accessed">
										Last Accessed: {Str.formatDate(policyholder.user.last_access)}
									</span>
								}
							</div>
							</div>
						</Link>
						<div className="search-result-bottom">
							{displays.subtitle && displays.subtitle.map(sub => (
								<div className="search-result-sub" key={sub}>
									{sub}
								</div>
							))}
							<div className="search-result-stats">
								{Object.keys(displays.policyCounts).map(policy => (
									<div className="search-result-sub" key={policy}>
										<PolicyTypeIcon className="search-result-policy-icon" policyType={policy} />
										{displays.policyCounts[policy]}
									</div>
								))}
							</div>
						</div>
					</div>
				</>
				:
				<>
					<hr className="bg-white m-0 p-0" />
					<div className="search-result-policyholder">
						<div className="search-result-top">
							<div className="search-result-name">
								{displays.title} {(policyholder && policyholder.user && policyholder.user.id) && <span className="search-result-name-user-id">- ID: {policyholder.user.id}</span>}
							</div>
						</div>
						<div className="search-result-bottom">
							{displays.subtitle && displays.subtitle.map(sub => (
								<div className="search-result-sub" key={sub}>
									{sub}
								</div>
							))}
						</div>
					</div>
				</>
			}
		</>
	)
}


const PolicyHolderList = ({ list, handleSearchClear, policyInput, hits, hidden }) => {
	return (
		<>
			{!list.length ?
				<div className="search-result-container">
					<h4>
						Search results for: {policyInput}
					</h4>
					<div className="search-results-info">
						<div hidden={hidden}>
							{hits} results found
						</div>
					</div>
					<div className="search">
					</div>
				</div>
				:
				<>
					<div className="search-result-container">
						<h4>
							Search results for: {policyInput}
						</h4>
						<div className="search-results-info mb-3">
							<div hidden={hidden}>
								{hits} results found
							</div>
						</div>
						<div className="search">
						</div>
					</div>

					<div className="agency-ams-clients-table-container">
						<div className="search-results">
							<div>
								{list.map(policyHolder => {
									return (
										<SearchPolicyholder
											key={policyHolder.policyHolderDigest}
											policyholder={policyHolder}
											searchQuery={policyInput}
											handleSearchClear={handleSearchClear}
										/>
									)
								})}
							</div>
						</div>
					</div>
				</>
			}
		</>
	)
}

export default PolicyHolderList;