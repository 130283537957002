import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import model from '../../model'

import { onFieldChange, onFormSubmit } from '../../utility/form'

const CarrierAdd = () => {
  const navigate = useNavigate()
  const [rec, setRec] = useState({
    id: '',
    name: '',
    division: '',
    groupID: '',
    theme: 'light',

    registerEntryURL: '',
    registerExitURL: '',
    forgotPasswordURL: '',
    loginURL: '',
    payBillURL: '',
    claimsURL: '',
    telematicsURL: '',
    roadsideServiceURL: '',
    roadsideServicePhone: '',
  })

  const handleChange = (name, value) => {
    const newRec = {
      ...rec,
      [name]: value,
    }
    setRec(newRec)
  }

  const handleSubmit = async () => {
    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      const add = {
        id: rec.id || null,
        name: rec.name || null,
        division: rec.division.toLowerCase() || 'personal',
        groupID: rec.groupID || rec.id,
        theme: rec.theme || 'light',

        registerEntryURL: rec.registerEntryURL || null,
        registerExitURL: rec.registerExitURL || null,
        forgotPasswordURL: rec.forgotPasswordURL || null,
        loginURL: rec.loginURL || null,
        payBillURL: rec.payBillURL || null,
        claimsURL: rec.claimsURL || null,
        telematicsURL: rec.telematicsURL || null,
        roadsideServiceURL: rec.roadsideServiceURL || null,
        roadsideServicePhone: rec.roadsideServicePhone || null,
      }

      await model.action('carrier.add', add)
      await model.action('app.setLoading', false)
      const next = `/carriers`
      navigate(next)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  return (
    <form className="my-3" onSubmit={e => onFormSubmit(e, handleSubmit)}>
      <div className="container section-container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h3 className="app-title-bar">
              Carrier Add
            </h3>

            <div className="section-title">
              Carrier Details
            </div>
            <div className="form-row">
              <label>ID</label>
              <input
                className="form-control"
                name="id"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Carrier ID"
                type="text"
                value={rec.id} />
            </div>
            <div className="form-row">
              <label>Name</label>
              <input
                className="form-control"
                name="name"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="Carrier name"
                type="text"
                value={rec.name} />
            </div>
            <div className="form-row">
              <label>Division</label>
              <div className="form-control">
                <div className="btn-group">
                  <div className={`btn ${rec.division === 'personal' ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('division', 'personal')}>
                    Personal
                  </div>
                  <div className={`btn ${rec.division === 'commercial' ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('division', 'commercial')}>
                    Commercial
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <label>Group ID</label>
              <input
                className="form-control"
                name="groupID"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="ID to group carriers"
                type="text"
                value={rec.groupID} />
            </div>
            <div className="form-row">
              <label>Theme</label>
              <div className="form-control">
                <div className="btn-group">
                  <div className={`btn ${rec.theme === 'light' ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('theme', 'light')}>
                    Light
                  </div>
                  <div className={`btn ${rec.theme === 'dark' ? 'btn-primary' : 'btn-dark'}`} onClick={() => handleChange('theme', 'dark')}>
                    Dark
                  </div>
                </div>
              </div>
            </div>

            <div className="section-title">
              Action Links
            </div>
            <div className="form-row">
              <label>Register Entry URL</label>
              <input
                className="form-control"
                name="registerEntryURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/register"
                type="text"
                value={rec.registerEntryURL} />
            </div>
            <div className="form-row">
              <label>Register Exit URL</label>
              <input
                className="form-control"
                name="registerExitURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/register/done"
                type="text"
                value={rec.registerExitURL} />
            </div>
            <div className="form-row">
              <label>Forgot Password URL</label>
              <input
                className="form-control"
                name="forgotPasswordURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/forgot-password"
                type="text"
                value={rec.forgotPasswordURL} />
            </div>
            <div className="form-row">
              <label>Login URL</label>
              <input
                className="form-control"
                name="loginURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/login"
                type="text"
                value={rec.loginURL} />
            </div>
            <div className="form-row">
              <label>Pay Bill URL</label>
              <input
                className="form-control"
                name="payBillURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/pay-bill"
                type="text"
                value={rec.payBillURL} />
            </div>
            <div className="form-row">
              <label>Claims URL</label>
              <input
                className="form-control"
                name="claimsURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/claims"
                type="text"
                value={rec.claimsURL} />
            </div>
            <div className="form-row">
              <label>Telematics URL</label>
              <input
                className="form-control"
                name="telematicsURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/telematics"
                type="text"
                value={rec.telematicsURL} />
            </div>
            <div className="form-row">
              <label>Roadside URL</label>
              <input
                className="form-control"
                name="roadsideServiceURL"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/roadsideServiceURL"
                type="text"
                value={rec.roadsideServiceURL} />
            </div>
            <div className="form-row">
              <label>Roadside Phone</label>
              <input
                className="form-control"
                name="roadsideServicePhone"
                onChange={e => onFieldChange(e, handleChange)}
                placeholder="https://carrier.com/roadsideServicePhone"
                type="text"
                value={rec.roadsideServicePhone} />
            </div>
            <div className="form-actions mt-4">
              <button className="btn btn-primary btn-block">Create Carrier</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CarrierAdd

