import constants from './constants'
import model from '../index'
import utilStr from '../../utility/str'
import config from '../../config'

const getAwsPoliciesNotConnected = ({ awsPolicies, connectedPolicies }) => {
	if (!awsPolicies) return []
	if (!connectedPolicies || connectedPolicies.length < 1) return awsPolicies

	let awsPoliciesNotConnected = []

	// get associated policies with carrier
	awsPolicies.forEach(policy => {
		if (!connectedPolicies) return
		const found = connectedPolicies.find(connectedPolicy => {
			return connectedPolicy.policyNumber === policy.policyNumber
		})
		if (!found) {
			awsPoliciesNotConnected.push(policy)
		}
	})

	return awsPoliciesNotConnected
}

const getCombinedCarriers = ({ policyholderCarriers, associatedCarriers }) => {
	let carriers = {}

	policyholderCarriers && Object.keys(policyholderCarriers).forEach(carrierId => {
		carriers[carrierId] = policyholderCarriers[carrierId] || {}
	})
	associatedCarriers && associatedCarriers.forEach(assoc => {
		const carrier = assoc.carrier
		if (!carrier || !carrier.id) return
		carriers[carrier.id] = carriers[carrier.id] ? { ...carriers[carrier.id], ...carrier } : { ...carrier }

		carriers[carrier.id].connectionStatus = assoc.connectionStatus
	})

	return carriers
}

const getCarrierPolicies = ({ carrier, associatedPolicies }) => {
	if (!associatedPolicies) return []
	let carrierPolicies = []

	// get associated policies with carrier
	Object.values(associatedPolicies).forEach(policies => {
		if (!policies || policies.length < 1) return
		policies.forEach(policy => {
			if (carrier.id === policy.carrierID) {
				carrierPolicies.push(policy)
			}
		})
	})

	return carrierPolicies
}

const getInfoMatchingQuery = ({ info, searchQuery }) => {
	const searchNormalized = utilStr.getSearchQueryNormalized(searchQuery)
	const props = ['names', 'phones', 'emails'] // list of info keys to search, in order of preference

	for (const i in props) {
		const prop = props[i]
		if (info[prop] && info[prop].length > 0) {

			const match = info[prop].find(val => {
				return utilStr.getSearchQueryNormalized(val).includes(searchNormalized)
			})
			if (match) return match
		}
	}
}

const getInfoPrimaryDetail = ({ info }) => {
	const props = ['names', 'phones', 'emails'] // list of info keys to search, in order of preference

	for (const i in props) {
		const prop = props[i]
		if (info[prop] && info[prop].length > 0) {
			if (info[prop] && info[prop].length > 0) return info[prop][0]
		}
	}
	return null
}

const getInfoSecondaryDetails = ({ info, primary }) => {
	if (!primary || !info) return;

	const primaryNormalized = utilStr.getSearchQueryNormalized(primary)
	const props = ['names', 'phones', 'emails'] // list of info keys to search, in order of preference

	let secondaryDetails = []

	for (const i in props) {
		const prop = props[i]
		if (info[prop] && info[prop].length > 0) {
			info[prop].forEach(val => {
				if (!utilStr.getSearchQueryNormalized(val).includes(primaryNormalized)) secondaryDetails.push(val)
			})
			if (secondaryDetails.length >= constants.maxNumDetails) return secondaryDetails
		}
	}
	return secondaryDetails
}

const getPolicyholderCarriers = (policyholder) => {
	let carriers = {}

	if (policyholder.amsPolicies) {
		policyholder.amsPolicies.forEach(policy => {
			if (!carriers[policy.carrierId]) carriers[policy.carrierId] = {}

			if (!carriers[policy.carrierId].updated || carriers[policy.carrierId].updated < policy.updated) {
				carriers[policy.carrierId].updated = policy.updated
			}

			carriers[policy.carrierId].policies = carriers[policy.carrierId].policies || []
			carriers[policy.carrierId].policies.push(policy)
		})
	}

	return carriers
}

const formatFullName = (firstName, lastName) => {
	const first = firstName === null ? '' : firstName
	const last = lastName === null ? '' : lastName
	return `${first} ${last}`.trim()
}

const getHijackUrl = (rec) => {
	return `${config.clientWebApp}/?userId=${rec.id}`
}

const formatName = ({ firstName, lastName }) => {
	const first = firstName === null ? '' : utilStr.capitalize(firstName)
	const last = lastName === null ? '' : utilStr.capitalize(lastName)
	return `${first} ${last}`.trim()
}

const getPolicyholderInfo = (policyholder) => {
	let info = {
		dob: null,
		names: [],
		phones: [],
		emails: [],
		policyCounts: {},
	}
	if (!policyholder) return info
	if ((policyholder.user && policyholder.user.firstName) || (policyholder.user && policyholder.user.lastName)) {
		info.names.push(formatFullName(policyholder.user.firstName, policyholder.user.lastName))
	}
	if (policyholder.user && policyholder.user.phone) info.phones.push(utilStr.PhoneFormattedPolicyHolder(policyholder.user.phone))
	if (policyholder.user && policyholder.user.email) info.emails.push(policyholder.user.email)

	if (policyholder.amsPolicies) {
		policyholder.amsPolicies.forEach(policy => {
			if (policy.holderFirstName && policy.holderLastName) {
				const formattedName = formatFullName(policy.holderFirstName, policy.holderLastName)
				if (!info.names.includes(formattedName)) info.names.push(formattedName)
			}
			if (policy.holderPhone && !info.phones.includes(utilStr.PhoneFormattedPolicyHolder(policy.holderPhone)))
				info.phones.push(utilStr.PhoneFormattedPolicyHolder(policy.holderPhone))
			if (policy.holderPhone2 && !info.phones.includes(utilStr.PhoneFormattedPolicyHolder(policy.holderPhone2)))
				info.phones.push(utilStr.PhoneFormattedPolicyHolder(policy.holderPhone2))
			if (policy.holderEmail && !info.emails.includes(policy.holderEmail))
				info.emails.push(policy.holderEmail)
			if (policy.holderEmail2 && !info.emails.includes(policy.holderEmail2))
				info.emails.push(policy.holderEmail2)
			if (policy.type) info.policyCounts[policy.type.id] = (info.policyCounts[policy.type.id] || 0) + 1
		})
	}

	return info
}

const getPolicyholderDisplays = ({ policyholder, searchQuery }) => {
	let displays = {
		title: 'Policyholder', // default title, will get overwritten if a better result is found
		subtitle: '',
		policyCounts: {},
	}

	const info = getPolicyholderInfo(policyholder)
	if (searchQuery) {
		const matchingDetail = getInfoMatchingQuery({ info, searchQuery })
		if (matchingDetail) {
			displays.title = matchingDetail
			displays.subtitle = getInfoSecondaryDetails({ info, primary: displays.title })
		} else {
			displays.title = getInfoPrimaryDetail({ info })
			displays.subtitle = getInfoSecondaryDetails({ info, primary: displays.title })
		}

	} else {
		displays.title = displays.title = getInfoPrimaryDetail({ info })
		displays.subtitle = getInfoSecondaryDetails({ info, primary: displays.title })
	}
	displays.policyCounts = info.policyCounts

	return displays
}

const isAdmin = (rec) => {
	if (!rec) return false
	if (['admin', 'gb_admin'].indexOf(rec.role.id) === -1) return false
	return true
}

const isPolicyholderVerifiedUser = (policyholder) => {
	return policyholder.user.phone || policyholder.user.email
}

model.registerHelpers(constants.model, {
	getAwsPoliciesNotConnected,
	getCarrierPolicies,
	getCombinedCarriers,
	getHijackUrl,
	getPolicyholderCarriers,
	getPolicyholderDisplays,
	getPolicyholderInfo,
	formatName,
	isAdmin,
	isPolicyholderVerifiedUser,
})
