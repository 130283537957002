import store from '../../store'
import config from '../../config'
import localStorage from '../../utility/localStorage'
import model from '../index'
import axios from 'axios'

const init = async ()=>{
	await model.action('user.init')
}

const getUserToken = async ()=>{
	return localStorage.get(config.userTokenName)
}

const generateAppToken = async ()=>{
    const url = getUrl({endpoint:'time'})
    const res = await axios.get(url)
    const header = {alg: 'HS256', typ: 'JWT'};
    const claim = {
        subtype:'app',
        iss: config.appID,
        sub: config.appID,
        aud: config.apiHost,
        iat: res.data.time.unix,
    }
    
    const sHeader = JSON.stringify(header)
    const sPayload = JSON.stringify(claim)
    const digest = window.KJUR.jws.JWS.sign("HS256", sHeader, sPayload, {b64: config.appSecret})
    return digest
}

const getAuthToken = async ()=>{ // detect device
	return await getUserToken()
}

const clearUserRec = async ()=>{
	return localStorage.clear(config.userRecName)
}

const clearUserToken = async ()=>{
	return localStorage.clear(config.userTokenName)
}

const getUrl = ({endpoint})=>{
	return `${config.domain}/api/${config.version}/${endpoint}`
}

const getDevice = ()=>{ // detect device
	if (window.innerWidth < 576 || window.innerHeight < 576){
		return 'mobile'
	}
	return 'desktop'
}

const setError = async (err)=>{
	store.dispatch({
		type:'APP_ERROR_SET',
		payload:err && err.toString? err.toString(): err,
	})
}
const setLoading = async (loading)=>{
	store.dispatch({
		type:'APP_LOADING_SET',
		payload:loading,
	})
}

const getUserRec = async ()=>{
	return localStorage.get(config.userRecName)
}

const setUserRec = async (rec)=>{
	return localStorage.set(config.userRecName, rec)
}

const setUserToken = async (token)=>{
	return localStorage.set(config.userTokenName, token)
}

model.registerActions('app', {
	init,
	clearUserRec,
	clearUserToken,
	generateAppToken,
	getAuthToken,
	getDevice,
	getUserToken,
	getUrl,
	setError,
	setLoading,
	setUserRec,
	setUserToken,
	getUserRec
})