import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AgencyCard from '../AgencyCard'
import AgencyDetails from './AgencyDetails'
import AgencyDeleteModal from './AgencyDeleteModal'
import { onFormSubmit } from '../../utility/form'

import model from '../../model'

const Agency = () => {
  let { agencyId } = useParams();
  const rec = useSelector(state => model.selector('agency.recById', { state, id: agencyId }));
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [recState, setRecState] = useState({});

  useEffect(() => {
    (async () => {
      try {
        await model.action('app.setLoading', true)
        await model.action('agency.getById', { agencyId })
        await model.action('agency.getFeatureFlags', { agencyID: agencyId })
        await model.action('app.setLoading', false)
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })();
  }, [agencyId]);

  useEffect(() => {
    setRecState({...rec})
  },[rec])

  const handleDeleteConfirm = async () => {
    try {
      await model.action('app.setLoading', true)
      await model.action('agency.remove', { rec })

      // go home
      navigate('/')
      await model.action('app.setLoading', false)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  const enabledDisabled = async () => {
    if (!recState.enabled) {
      setRecState({ ...recState, enabled: true })
    } else {
      setRecState({ ...recState, enabled: false })
    }
  }

  const handleSubmit = async () => {
    let saveRec = { ...recState }
    
    Object.keys(saveRec).forEach((i) => {
      if (saveRec[i] === '') saveRec[i] = null
      if (i === 'address') {
        Object.keys(saveRec.address).forEach((j) => {
          if (!saveRec[i][j]) saveRec[i][j] = null
        })
      }
    })

    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      await model.action('agency.save', saveRec)
      await model.action('app.setLoading', false)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  const isRecChanged = Object.keys(recState).find(key => {
    if (key === 'updated' || key === 'image' || key === 'agents') return false

    if (key === 'address') {
      if (JSON.stringify(rec[key]) !== JSON.stringify(recState[key])) {
        return true
      }
      return false
    }
    if (rec[key] !== recState[key]) {
      return true
    } else {

      return false
    }
  }) !== undefined

  return (
    <>
    {!rec ? <div /> :
      <React.Fragment>
        {showModal === 'delete' && <AgencyDeleteModal handleClose={() => setShowModal(null)} handleConfirm={handleDeleteConfirm} />}
        <div className="my-3">
          <div className="container section-container">
            <div className="row justify-content-center">
              <div className="col-md-7">
                <h3 className="app-title-bar agent-title">
                  {rec.name}
                </h3>
              </div>
              <form className="col-md-1 toggle-agent pt-1" onSubmit={e => onFormSubmit(e, handleSubmit)}>
                <div className={`input-toggle ${recState.enabled === false ? 'input-toggle-off' : ''}`} onClick={enabledDisabled}>
                  <div className="input-toggle-knob" />
                </div>
                {isRecChanged && (
                  <div className="save-bar">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-md-8">
                          <div className="clearfix text-right">
                            <span className="btn btn-outline-light float-left" onClick={() => setRecState(rec)}>Revert</span>
                            Save your changes <button className="btn btn-primary ml-2">Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
            <AgencyCard rec={rec} />
            <AgencyDetails rec={rec} />
            <div className="row justify-content-center">
              <div className="col-md-8 d-none">
                <div className="section-title text-muted">
                  <span onClick={() => setShowModal('delete')} className="float-right btn btn-dark">
                    <i className="far fa-trash fa-fw"></i> Delete Agency
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    }
  </>
  )
}

export default Agency

