import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import AgencyAgentCard from '../AgencyAgentCard'
import ModalPurge from '../PolicyHolders/ModalPurge'
import model from '../../model'
import utilStr from '../../utility/str'
import { onFieldChange, onFormSubmit } from '../../utility/form'

const PolicyHolderView = () => {
    let { userId } = useParams();
    const userIdNumber = Number(userId);
    const navigate = useNavigate();
    const user = useSelector(state => model.selector('user.rec', state));
    const [agency, setAgency] = useState(null);
    const [modal, setModal] = useState(null);
    const [toolTip, setToolTip] = useState(null);
    const [modalUserId, setModalUserId] = useState(null);
    const [rec, setRec] = useState({
        code: ''
    });
    const [userPageInfo, setUserPageInfo] = useState({})
    const [role, setRole] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                const getUser = await model.action('user.getRecFromRemote', userId)
                const getRole = await model.action('user.getUserAgencyRole', { userId })
                const getAgency = await model.action('agency.getUserAgencies', { userId })
                setRole(getRole)
                setAgency(getAgency.data[getAgency.data.length - 1])
                setUserPageInfo(getUser)
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                await model.action('app.setError', e)
            }
        })();
    }, [userId])

    const handleDelete = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('user.remove', { userId: modalUserId })
            setModal(null)
            setModalUserId(null)
            navigate('/users')
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handlePurge = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('user.purgeUser', { userId: modalUserId })
            setModal(null)
            setModalUserId(null)
            navigate('/users')
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handleModalClose = () => {
        setModal(null)
        setModalUserId(null)
    }

    const handleToolTipToggle = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (toolTip) {
            setToolTip(false);
        } else {
            setToolTip(true);
        }
    }

    const handleChange = (name, value) => {
        const newRec = {
            ...rec,
            [name]: value,
        }
        setRec(newRec);
    }

    const handleSubmit = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('agency.connect', { code: rec.code, userId })
            const getAgency = await model.action('agency.getUserAgencies', { userId })
            setAgency(getAgency.data[getAgency.data.length - 1])
            setRec({
                code: ''
            })
            navigate(`/users/${userId}`)
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const disconnectAgency = async () => {
        try {
            await model.action('app.setLoading', true)
            if (agency && agency.agencyID) {
                await model.action('agency.removeAgencyConnection', { userId, agencyId: agency.agencyID, agentId: agency.id })
            } else {
                await model.action('agency.removeAgencyConnection', { userId, agencyId: agency.id })

            }
            const getAgency = await model.action('agency.getUserAgencies', { userId })
            setAgency(getAgency.data[getAgency.data.length - 1])
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handleModalShow = ({ userId }) => {
        setModal('delete');
        setModalUserId(userId);
    }

    const handlePurgeModal = ({ userId }) => {
        setModal('purge');
        setModalUserId(userId);
    }

    // const handleLaunch = (e) => {
    //     e.stopPropagation()
    //     window.open(`${model.helper('user.getHijackUrl', userPageInfo)}`, "_blank")
    // }

    const handleLaunchPolicyHolder = (e) => {
        e.stopPropagation()
        window.open(`${model.helper('agency.hiJackHqPolicyHolder', { agency, userId })}`, "_blank")
    }

    const userDetails = () => {
        return (
            <>
                {userId ?
                    <React.Fragment>
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <form className="position-relative mt-5" key="phone" onSubmit={e => onFormSubmit(e, handleSubmit)}>
                                    <div className="d-flex justify-content-center flex-column">
                                        <input autoFocus
                                            className="form-control form-control-gb-lg-size form-control-white"
                                            key="email"
                                            name="code"
                                            onChange={e => onFieldChange(e, handleChange)}
                                            placeholder="Start typing an agency code..."
                                            type="code"
                                            inputMode='text'
                                            value={rec.code} />
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button className="btn btn-primary float-right mt-3">
                                                <span className="quote-text">CONNECT</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="pt-5 flex justify-center">
                                    {agency &&
                                        <div onClick={disconnectAgency} className="underline hover:text-red-600 cursor-pointer">
                                            <span className="quote-text">Disconnect Agency</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-5 pb-5">
                            <div className="col-md-8">
                                <div className="section-title mt-3">
                                    User Actions
                                </div>
                                <div className="detail-row mt-3">
                                    <div className="w-100 d-flex flex-row ml-1 align-items-center justify-content-around">
                                        <button disabled={user.id === userIdNumber ? true : false} onClick={() => handleModalShow({ userId })} className="btn-search square-btn btn-outline-subtle">
                                            <span>DELETE USER</span>
                                        </button>
                                        {user.role.id === 'admin' &&
                                            <button className="btn-search square-btn btn-outline-subtle" onClick={() => handlePurgeModal({ userId })} onMouseEnter={(e) => handleToolTipToggle(e)} onMouseLeave={(e) => handleToolTipToggle(e)}>
                                                PURGE USER
                                            </button>}
                                        {(agency) &&
                                            <button disabled={!agency ? true : false} onClick={handleLaunchPolicyHolder} className="btn-search square-btn btn-outline-subtle">
                                                HQ
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            {modal === 'delete' && <ModalPurge handleClose={handleModalClose} handleSubmit={handleDelete} headlineText="Confirm User Delete" questionText="Are you sure you want to delete this user?" />}
                            {modal === 'purge' && <ModalPurge handleClose={handleModalClose} handleSubmit={handlePurge} headlineText="Confirm Data Erasure" questionText="Are you sure you want to purge this user?" />}
                            <div className="col-md-8">
                                <div className="section-title mt-3">
                                    User Information
                                </div>
                                {(userPageInfo && userPageInfo.id) &&
                                    <div className="form-row">
                                        <label>ID</label>
                                        <input
                                            readOnly
                                            className="form-control"
                                            name="phone"
                                            placeholder="phone"
                                            type="text"
                                            value={`${userPageInfo.id}`} />
                                    </div>
                                }
                                {((userPageInfo) && (userPageInfo.firstName || userPageInfo.lastName)) &&
                                    <div className="form-row">
                                        <label>Name</label>
                                        <input
                                            readOnly
                                            className="form-control"
                                            name="phone"
                                            placeholder="phone"
                                            type="text"
                                            value={`${userPageInfo.firstName ? userPageInfo.firstName : ''} ${userPageInfo.lastName ? userPageInfo.lastName : ''}`} />
                                    </div>
                                }
                                {(userPageInfo && userPageInfo.email) &&
                                    <div className="form-row">
                                        <label>Email</label>
                                        <input
                                            readOnly
                                            className="form-control"
                                            name="email"
                                            placeholder="Email"
                                            type="text"
                                            value={`${userPageInfo.email}`} />
                                    </div>
                                }
                                {(userPageInfo && userPageInfo.phone) &&
                                    <div className="form-row">
                                        <label>Phone</label>
                                        <input
                                            readOnly
                                            className="form-control"
                                            name="phone"
                                            placeholder="phone"
                                            type="text"
                                            value={`${utilStr.PhoneFormattedPolicyHolder(userPageInfo.phone)}`} />
                                    </div>
                                }
                                {(role && role.length > 0) &&
                                    <div className="form-row">
                                        <label>Agency Role - Agency ID</label>
                                        <div className="">
                                            {role.map((item, index) => (
                                                <input
                                                    key={index}
                                                    readOnly
                                                    className="form-control"
                                                    name="role"
                                                    placeholder="role"
                                                    type="text"
                                                    value={`${item.relation} - ${item.agencyID}`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    </React.Fragment>
                }
            </>
        )
    }

    return (
        <div className="my-3 pt-3">
            <div className="container section-container">
                {agency && <AgencyAgentCard rec={agency} />}
                {userDetails()}
            </div>
        </div>
    )
}

export default PolicyHolderView