import React from 'react'

const Tooltip = ({className, tip, secondaryClass})=>{
	return (
		<div className={`${secondaryClass}`}>
			<div className={`${className? className: ''}`}>
				<span>{tip}</span>
			</div>
		</div>
	)
}

export default Tooltip