const state = {
	list: null,
	rec: null,
	recs: {},
	policyHolder: null,
	searchQuery: null
}

export const reducer = (st = state, action) => {
	switch (action.type) {
		case 'USER_AGENT_REC_CLEAR': {
			return { ...st, rec: null }
		}
		case 'USER_AGENT_REC_SET': {
			const { rec } = action.payload
			return { ...st, rec }
		}
		case 'USER_LIST_SET': {
			const { list } = action.payload
			return { ...st, list }
		}
		case 'USER_REC_SET': {
			const { rec } = action.payload
			const recs = {
				...st.recs,
				[rec.id]: rec
			}
			return { ...st, recs }
		}
		case 'POLICY_HOLDER_SET': {
			const { rec } = action.payload;
			return { ...st, policyHolder: rec }
		}
		case 'SEARCH_QUERY_SET_POLICY_HOLDER': {
			const { searchQuery } = action.payload
			return { ...st, searchQuery }
		}
		default:
			return st
	}
}