import React from 'react'

const Loader = () => {
	return (
		<div className="cover">
			<div className="cover-bg" />
			<div className="center text-center">
				<div className="spinner-border text-white" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	)
}

export default Loader